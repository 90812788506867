import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'app/hooks'
import { Link } from 'react-router-dom'
import { getLinkSelector, useAppSelector } from 'app/hooks'
import { navigateAsync, navigateFeature, parseNav } from 'actions/appActions'
import { CustomEventType } from 'app/types'

interface BreadCrumbProps {
    app: AppData,
    splitIndex?: number,
    forwardRef: Ref.Object,
    showData?: boolean,
    showPage?: boolean,
    showApp?: boolean,
    fixed?: boolean,
}

export function BreadCrumbs(props: BreadCrumbProps) {
    const { app, splitIndex, forwardRef, showData, showPage, showApp, fixed } = { showData: true, showPage: true, showApp: true, ...props }
    const dispatch = useAppDispatch()
    const navigation = useAppSelector((state: RootState) => state.app.navigation[splitIndex ? splitIndex : 0])
    const compare = useAppSelector((state: RootState) => state.app.compare)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const config = useAppSelector((state: RootState) => state.app.config)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const [items, setItems] = useState([])

    if(true || splitIndex != null) {
        return null
    }

    useEffect(() => {
        if (!navigation) {
            return
        }

        let { baseRoute, organizationLink, appLink, pageLink, dataLink, links } = parseNav(navigation, organization, { organization, customDomain })
        let appIndex = links.findIndex((x) => x == appLink)
        let newItems = navigation.split('/').filter((x) => x.length > 0).map((x) => {
            let name = x
            if (app && x == app.meta.id) {
                name = app.meta.name
            }
            return { id: x, name: name }
        })

        if (organization != null && organization.link != 'homegyde') {
            baseRoute = `${organizationLink}/${baseRoute}`
            newItems.shift()
            newItems[0].id = baseRoute
        }

        let finalItems = []
        for (let i = 0; i < newItems.length; i += 1) {
            let x = newItems[i]
            let ix = i
            if ((!showApp && ix == appIndex)
                || (!showPage && ix == appIndex + 1)
                || (!showData && ix == appIndex + 2)) {
                break
            }
            finalItems.push({
                id: x.id,
                text: x.name.toTitleCase(),
                link: `/${newItems.slice(0, ix + 1).map((x) => x.id).join('/')}`
            })
        }
        setItems(finalItems)
    }, [navigation, app])

    function handleClick(x, ix) {
        // If app, check for index of app page
        if (app) {
            let appIndex = items.findIndex((x) => x.id == app.meta.id)
            if (appIndex != -1 && ix > appIndex) {
                dispatch(navigateAsync({ app: app, pageType: x.id, options: { splitIndex } }))
            } else if (ix == appIndex) {
                dispatch(navigateFeature(''))
                // dispatch(navigateAsync({app: app, pageType: '', options: { splitIndex }}))
            } else if (ix > 0 || standaloneApp) {
                dispatch(navigateAsync({ pageType: x.id, options: { splitIndex } }))
            } else {
                dispatch(navigateAsync({ pageType: '', options: { splitIndex } }))
            }
        } else {
            // dispatch(navigateFeature(''))
            dispatch(navigateAsync({ pageType: '', options: { splitIndex } }))
        }
    }


    return <div className={`breadcrumbs content-wrapper${fixed ? ' fixed': ''}`} ref={forwardRef}>
        {items.map((x, ix) => {
            return <React.Fragment key={ix}>
                {ix < items.length - 1 && !compare && <Link to={x.link}><span className="noselect">{x.text}</span></Link>}
                {ix < items.length - 1 && compare && <a onClick={() => handleClick(x, ix)}><span className="noselect">{x.text}</span></a>}
                {ix == items.length - 1 && <span className="noselect">{x.text}</span>}
                {ix < items.length - 1 && <span className="noselect">></span>}
            </React.Fragment>
        })}
    </div>
}