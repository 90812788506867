import {
    createSlice,
    createAsyncThunk,
    // PayloadAction,
} from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import {
    getApp,
    getAllApps,
    getAllMedia,
    postEmail,
    getConfig,
    postAnalytics,
    postBooking,
    getFavourites,
    getUpgrades,
    putUpgradeProduct,
    postUpgradeLog,
    getUpgradeOrder,
    postUpgradeNotify,
    postUpgradeComplete,
    postUpgradePdf,
    getUpgradeLastChange,
    postUpgradeDocument,
    postUpgradeDocumentDownload,
    getUpgradeLogs,
} from 'services/appServices'
import { saveState, loadState, getSession, applyCookies } from 'app/state'
import {
    AdminDelta,
    AdminView,
    Analytics,
    AppState,
    AppData,
    AppMetaData,
    MediaData,
    ColorScheme,
    EmailType,
    EmailOptions,
    ErrorMessage,
    ProjectFilter,
    FloorplanFilter,
    MinMax,
    PageData,
    PageOptions,
    PageType,
    PromptOptions,
    PromptData,
    Query,
    QueryType,
    Range,
    RootState,
    ScreenOrientation,
    ScreenSize,
    UnitFilter,
    AvailabilityAnalytics,
    EmailAnalytics,
    FloorplanAnalytics,
    AnalyticsSource,
    ListStyle,
    Dict,
    OrganizationData,
    AnalyticsEvent,
    NotificationStatus,
    UpgradeProductData,
    CustomEventType,
    SegmentOptions,
    UpgradeOrderRecordData,
    UpgradeLogData,
} from 'app/types'
import { setQueryHelper, toggleQueryHelper, resetQueryHelper } from 'helpers/query'
import { checkVersion, clearCache, getAppPath, getAppUrl, getWindowLocation } from 'helpers/config'
import { logger } from 'helpers/logger'
import history from 'helpers/routerHistory'
import * as fnc from 'helpers/fnc'
import { getMediaLink } from 'helpers/media'
import qs from 'qs'
import { acceptedBaseRoutes } from 'app/constants'
import { getJWTKey, validateEmail } from 'helpers/authHeader'
import { facebookPixelRecordEvent, getFacebookTrackingId as getFacebookTrackingId, getGoogleTrackingId, getHotjarUserId, googleTagReportConversion, hubspotIdentifyUser } from 'helpers/scripts'
import { setChangePending, resetDelta, updateProject } from './adminActions'
import {
    setupApp,
    setupAppMaps,
    setupAppMeta,
    initialRanges,
    retryOperation,
    setupUpgrades,
} from './actionHelpers'
import { loadUser } from './userActions'

const isMobile = fnc.isMobileDevice()
const debugErrors = process.env.DEBUG == 1
const fullscreenExclusions = ['iPhone', 'iPad']

// const cookies = new Cookies()
function getStackTrace() {
    let stack = new Error().stack || ''
    stack = stack.split('\n').map((line) => line.trim())
    return stack.splice(stack[0] === 'Error' ? 2 : 1)
}
function handleError(e, action, key = null) {
    const msg = `(${key ? key : ''}) ${(typeof e === 'object' ? JSON.stringify(e) : e)} STACK: ${getStackTrace()} ACTION: ${JSON.stringify(action)}`
    logger.error(msg)
    return debugErrors ? msg : ErrorMessage.LoadError
}

function setAnalyticsSource(state) {
    state.analytics.source = state.salescenter ? AnalyticsSource.SalesCenter : AnalyticsSource.Website
}


/**
 * Initial state
 */
const initialQueries = {}
for (let i = 0; i < Object.keys(QueryType).length; i += 1) {
    const key = Object.keys(QueryType)[i]
    initialQueries[QueryType[key]] = {}
}
const initialState: AppState = {
    initialized: 0,
    error: null,
    fixedQueries: {},
    standaloneApp: false,
    standaloneOrg: false,
    current: null,
    media: {},
    fullscreen: false,
    prompt: null,
    current: null,
    projectCurrent: [null, null],
    cookies: false,//fnc.getCookie('cookieConsent') === 'true',
    history: [getWindowLocation().pathname],
    popState: null,
    projectPath: '/', // Where project listing resides
    rootPath: '/', // Home page
    config: {
        theme: null,
        colorScheme: null,
    },
    links: {},
    compare: false,
    compareCommunities: false,
    compareMode: PageType.Floorplan,
    mobileMode: localStorage.getItem('mobile-mode') || ListStyle.Grid,
    onlyFavourites: false,
    navigation: [null, null],
    checkpoint: null,
    screen: {
        size: ScreenSize.Desktop,
        orientation: ScreenOrientation.Landscape,
        isMobile: fnc.isMobileDevice(),
        isTouch: fnc.isTouchDevice(),
        userAgent: window.navigator.userAgent,
        focus: false,
    },
    queries: { ...fnc.copyObj(initialQueries) },
    salescenter: false,
    stateHash: null,
    version: 0,
    showBuilding: true,
    showSitemap: true,
    idle: false,
    notifications: [],
    analytics: {
        teamId: null,
        referralId: null,
        facebookId: null,
        googleId: null,
        ...getSession(),
    },
    organizationVisited: false,
    customDomain: {},
    debug: false,
    exclusive: false,
    ...loadState('app', true, true),
}

initialState.cookies = true


// Initialize source
setAnalyticsSource(initialState)

/**
 * State / History
 */
function getStateSlice(state: AppState) {
    const slice = {
        cookies: state.cookies,
        queries: state.queries,
        // favourites: state.favourites,
        navigation: state.navigation,
        // teamId: state.teamId,
        // refId: state.refId,
        // standaloneApp: !state.customDomain.app && state.standaloneApp,
        exclusive: state.exclusive,
        config: {
            colorScheme: state.config.colorScheme,
            theme: state.config.theme,
        },
    }
    // if (state.fixedQueries) {
    // slice.fixedQueries = state.fixedQueries
    // }
    return slice
}

function saveAppState(state: AppState, updateWindowHash = false, map: () => AppState) {
    let stateSlice = getStateSlice(state)
    if (map) {
        stateSlice = map(stateSlice)
    }
    const hashCode = saveState('app', stateSlice)

    // Change hash to match query
    if (updateWindowHash) {
        setTimeout(() => {
            setHash(hashCode)
        }, 0)
    }

    state.stateHash = hashCode
    return hashCode
}

export function setHash(hashCode) {
    const currentState = { ...history.location }
    currentState.hash = hashCode
    history.replace(currentState)
}

/**
 * Add a history element to states navigation
 */
/* function pushHistory(state:AppState, link:string) {
    if (getWindowLocation().pathname !== link) {
        state.history.push(link)
        history.push(link)
        state.navigation = [link.replace(`${state.appPath}`, ''), null]
    }
} */

/**
 * Generate a history list from an array of navigation values
 */
function historyFromLinks(links: [string], state: AppState = null) {
    const ret = []
    for (let i = 0; i < links.length; i += 1) {
        const path = links.slice(0, i + 1)
        const link = `${state ? `${state.rootPath}` : ''}${path.join('/')}`
        ret.push(link)
    }
    return ret
}

/**
 * Whether the current url is the admin page
 */
function onAdminPage() {
    return getWindowLocation().href.split('/').find((x) => x === 'admin') != null
}

/**
 * Add media
 */
function addMedia(state: AppState, media: MediaData[]) {
    if (media) {
        media.forEach((x: MediaData) => {
            state.media[x.id] = x
        })
    }
}

/**
 * Calculate ranges
 */
function calculateProjectRanges(state: AppState) {
    // Generate filter ranges
    const bedSet: Set = new Set()
    const bathSet: Set = new Set()
    const garageSet: Set = new Set()
    const builderSet: Set = new Set()
    const typeSet: Set = new Set()
    const buildingSet: Set = new Set()
    const statusSet: Set = new Set()
    const newRange: Range = fnc.copyObj<Range>(initialRanges)
    let { apps } = state.config
    if (state.organization) {
        const appSet = new Set(state.organization.apps)
        apps = apps.filter((x) => appSet.has(x.meta.id))
    } else if (state.queries[QueryType.Projects][ProjectFilter.Organization] != null) {
        const orgMap = Object.assign({}, ...state.config.organizations.map((x) => ({ [x.id]: x })))
        const appSet = new Set()
        const limit = state.queries[QueryType.Projects][ProjectFilter.Organization].length
        for (let i = 0; i < limit; i += 1) {
            const org = orgMap[state.queries[QueryType.Projects][ProjectFilter.Organization][i]]
            if (org != null) {
                org.apps.forEach((x) => appSet.add(x))
            }
        }
        apps = apps.filter((x) => appSet.has(x.meta.id))
    }
    for (let i = 0; i < apps.length; i += 1) {
        builderSet.add(apps[i].meta.builderId)
        const stats = apps[i].meta.stats
        stats.beds.forEach((x) => bedSet.add(x))
        stats.baths.forEach((x) => bathSet.add(x))
        stats.garages?.forEach((x) => garageSet.add(x))
        state.config.apps[i].meta.projectTypes.forEach((x) => typeSet.add(x))
        if (state.config.apps[i].meta.projectStatusId) {
            statusSet.add(state.config.apps[i].meta.projectStatusId)
        }
        stats.price.forEach((x) => {
            newRange[FloorplanFilter.Price].min = Math.min(newRange[FloorplanFilter.Price].min, x)
            newRange[FloorplanFilter.Price].max = Math.max(newRange[FloorplanFilter.Price].max, x)
        })
        stats.sqft.forEach((x) => {
            newRange[FloorplanFilter.Sqft].min = Math.min(newRange[FloorplanFilter.Sqft].min, x)
            newRange[FloorplanFilter.Sqft].max = Math.max(newRange[FloorplanFilter.Sqft].max, x)
        })
    }
    if (newRange[FloorplanFilter.Price].max < newRange[FloorplanFilter.Price].min) {
        newRange[FloorplanFilter.Price] = { min: 0, max: 5000000 }
    }
    newRange[FloorplanFilter.Beds] = Array.from(bedSet).sort((a, b) => a - b)
    newRange[FloorplanFilter.Baths] = Array.from(bathSet).sort((a, b) => a - b)
    newRange[FloorplanFilter.Garages] = Array.from(garageSet).sort((a, b) => a - b)
    newRange[ProjectFilter.ProjectStatus] = Array.from(statusSet).map((x) => state.config.projectStatuses[x])
    newRange[ProjectFilter.ProjectType] = Array.from(typeSet).map((x) => state.config.projectTypes[x]).filter((x) => x != null)
    newRange[ProjectFilter.Builder] = state.config.builders.filter((x) => builderSet.has(x.id))
    state.config.filterRanges = newRange
}

/**
 * Setup Home Gyde
 */
function setupHomeMeta(state: AppState) {
    if (state.customDomain) {
        if (state.customDomain.app) {
            state.projectPath = getPath(null, null, null)
            state.rootPath = getPath(null, null)
        }
    } else {
        state.projectPath = getPath(state.organization ? state.organization.link : null, null, PageType.Developments) // TODO, non-magic var here
        state.rootPath = getPath(state.organization ? state.organization.link : null, null) // TODO, non-magic var here
    }

    if (!state.standalone) {
        if (state.organization) {
            document.title = state.organization.name
        } else {
            document.title = 'Home Gyde'
            // fnc.changeFavicon('assets/favicon.png')
        }
    }
}
function setupHome(state: AppState, payload: AppState) {
    state.initialized = 2
    // state.organizations = action.payload.organization
    // Helper maps
    state.config.apps = payload.apps.map((x) => ({ meta: x }))
    state.config.builders = payload.builders
    // Apply builders to apps
    state.config.maps = {}
    state.config.maps.organization = state.config.organizations
        .reduce((a, x, ix) => ({ ...a, [x.id]: x }), {})
    state.config.maps.builder = state.config.builders
        .reduce((a, x, ix) => ({ ...a, [x.id]: x }), {})
    state.organization = payload.organization

    calculateProjectRanges(state)

    // Now have all queryable info, parse url queries
    const query = qs.parse(getWindowLocation().search.replace('?', ''))
    const queryKeys = Object.keys(query)
    const queryValues = Object.values(query)
    for (let i = 0; i < queryKeys.length; i += 1) {
        const key = queryKeys[i]
        switch (key) {
            default:
                break
            case 'organization':
                const orgs = queryValues[i].split(',')
                const organizationIds = []
                for (let j = 0; j < orgs.length; j += 1) {
                    const org = state.config.organizations.find((x) => x.link === orgs[j])
                    if (org) {
                        organizationIds.push(org.id)
                    }
                }
                if (organizationIds.length > 0) {
                    if (!(QueryType.Projects in state.queries)) {
                        state.queries[QueryType.Projects] = {}
                    }
                    state.queries[QueryType.Projects][ProjectFilter.Organization] = organizationIds
                }
                break
        }
    }
    saveAppState(state)
}

function setupStandalone(state: AppState, payload: AppState) {
    /*state.initialized = 2
    // Helper maps
    state.config.maps = {}
    state.config.apps = payload.apps.map((x) => ({ meta: x }))
    state.config.builders = payload.builders
    // Apply builders to apps
    state.config.maps.builder = state.config.builders
        .reduce((a, x, ix) => ({ ...a, [x.id]: ix }), {})
    state.organization = payload.organization
    calculateProjectRanges(state)*/
}

/**
 * Get path from state path
 */
function getPath(organizationLink: string = null,
    appLink: string = null,
    baseRoute: string = null) {
    const links = [organizationLink, baseRoute, appLink]
    const path = `${links.filter((x) => x != null && x.length > 0).join('/')}`
    return path.length > 0 ? `/${path}/` : '/'
}

/**
 * Convert nav string to links
 */
export function parseNav(nav: string, organization: OrganizationData, state: AppState) {
    let organizationLink = null
    let baseRoute = null
    let appLink = null
    let pageLink = null
    let dataLink = null
    let extraLink = null
    let extraExtraLink = null
    if (nav == null) {
        return { organizationLink, baseRoute, appLink, pageLink, dataLink, extraLink }
    }
    const links = nav.replace(getAppPath(), '').split('/').filter((x) => x.length > 0)
    if (links.length > 0) {
        if (organization && organization.link != 'homegyde' && !state.customDomain.organization && !state.standaloneApp) {
            organizationLink = links.length > 0 ? links[0] : null
            links.shift()
        }
        baseRoute = links.length > 0 ? links[0] : null


        // Base route must be accepted route TODO: place in variable conswhere
        if (acceptedBaseRoutes.includes(baseRoute)) {
            appLink = links.length > 1 ? links[1] : null
            pageLink = links.length > 2 ? links[2] : null
            dataLink = links.length > 3 ? links[3] : null
            extraLink = links.length > 4 ? links[4] : null
            extraExtraLink = links.length > 5 ? links[5] : null
        } else {
            baseRoute = null
            appLink = links.length > 0 ? links[0] : null
            pageLink = links.length > 1 ? links[1] : null
            dataLink = links.length > 2 ? links[2] : null
            extraLink = links.length > 3 ? links[3] : null
            extraExtraLink = links.length > 4 ? links[4] : null
        }
    }

    // If custom domain, inject and shift links TODO: something less janky
    if (state.customDomain.app) {
        extraLink = dataLink
        dataLink = pageLink
        pageLink = appLink
        organizationLink = state.organization?.link
        appLink = state.customDomain.app
    } else if (state.customDomain.organization) {
        /*appLink = organizationLink
        pageLink = dataLink
        dataLink = extraLink
        extraLink = extraExtraLink*/
        organizationLink = state.customDomain.organization
    } else if (state.standaloneApp) {
        organizationLink = state.organization?.link
    }

    return { organizationLink, baseRoute, appLink, pageLink, dataLink, extraLink, links }
}
/**
 * Setup history
 */
function setupHistory(state: AppState, options = {}) {
    // Get navigation
    const { excludeAppPath } = options
    let fixedPath = state.rootPath.length > 1 ? fnc.trimTrailingSlash(state.rootPath) : state.rootPath
    let { organizationLink, appLink, pageLink, dataLink, extraLink, baseRoute, links } = parseNav(getWindowLocation().pathname, state.organization, state)

    state.links = {
        baseRoute,
        organizationLink,
        appLink,
        pageLink,
        dataLink,
        extraLink,
    }
    const prevNav = parseNav(state.prevLocation, state.organization, state)
    state.prevLinks = prevNav

    if (state.checkpoint && (prevNav.appLink != appLink
        || (prevNav.baseRoute != baseRoute && prevNav.baseRoute == PageType.Favourite)
        || (baseRoute == PageType.Developments && appLink == null))) {
        state.checkpoint = null
    }

    if (pageLink !== PageType.Compare && appLink !== PageType.Compare) {
        state.compare = false

        // Enable route states
        if (state.projectPath.includes(PageType.Favourite) || pageLink == PageType.Favourite || baseRoute == PageType.Favourite) {
            state.onlyFavourites = true
        } else if (dataLink == null) {
            state.onlyFavourites = false
        }

        // Set checkpoint if necessary
        if (!state.standaloneOrg && prevNav.baseRoute == PageType.Favourite && baseRoute != PageType.Favourite && appLink != null) {
            state.checkpoint = { navigate: { pageType: '', options: { path: { baseRoute: PageType.Favourite } } }, hint: 'Favourites' }
        }
        // If going to favourites and organization with 1 app, back should be the 1 app
        if (baseRoute == PageType.Favourite && state.standaloneOrg) {
            const app = state.config.apps.find((x) => x.meta.id == state.organization.apps[0])
            state.checkpoint = { navigate: { app, pageType: '', options: { path: { baseRoute: null } } }, hint: 'Back' }
        }

        state.history = historyFromLinks(links, state)
        // Fix favourites history
        if (state.onlyFavourites) {
            if (!excludeAppPath) {
                if (dataLink != null) {
                    // Fix "floorplans" root to instead direct to "favourites"
                    const floorplansIndex = links.findIndex((x) => x === PageType.Floorplan)
                    if (floorplansIndex !== -1) {
                        state.history[floorplansIndex] = state.history[floorplansIndex].replace(PageType.Floorplan, PageType.Favourite)
                    }
                }
            } else if (appLink !== PageType.Compare) {
                // Global favourites, eliminate app route
                const appPath = `/${baseRoute}/${appLink}`
                const appIndex = state.history.findIndex((x) => x === appPath)
                if (appIndex !== -1) {
                    state.history.splice(appIndex, 1)
                }
            }
        }

        state.navigation[0] = state.history[state.history.length - 1]
        state.navigation[1] = null
    } else if (!state.compare) {
        // Initialize compare
        if (state.navigation[1] == null) { // Indicates not coming from hash loaded nav state
            if (state.prevLocation) {
                state.navigation[0] = state.prevLocation.replace(getAppPath(), '')
            } else {
                state.navigation[0] = null
            }
        }
        state.compare = true
        let root = '/'
        if (state.customDomain) {
            root = `/${baseRoute ? `${baseRoute}/` : ''}`
        } else {
            root = `/${[organizationLink, baseRoute].filter((x) => x != null).join('/')}`
            if (root.length > 1) {
                root = `${root}/`
            }
        }
        // Set starting point of other split
        if (!appLink || appLink === PageType.Compare) {
            // Set compare mode depending on left nav
            // Get prev
            state.compareCommunities = prevNav.appLink == null
            if (state.compareCommunities) {
                state.projectCurrent = [null, null]
            }

            // Convert sitemap link to floorplan link
            if (false && prevNav.pageLink == PageType.Sitemap) { // || prevNav.pageLink == PageType.Building) {
                prevNav.pageLink = PageType.Floorplan
                if (prevNav.extraLink) {
                    const units = prevNav.extraLink.split(',')
                    // TODO, use current project to lookup units correct
                    delete prevNav.extraLink

                    // TODO, THE BELOW IS FUDGED
                    // let floorplanLink = null
                    // Find
                    try {
                        for (let i = 0; i < units.length; i += 1) {
                            const fp = state.projectCurrent[0].floorplans.find((x) => x.id == units[i])
                            if (fp != null) {
                                prevNav.dataLink = fp.link
                                break
                            }
                        }
                    } catch (e) {
                        logger.error('Failed to lookup floorplans', e)
                    }
                }
                if (state.customDomain.app) {
                    state.navigation[0] = `${root}/${prevNav.appLink}/${prevNav.pageLink}`
                } else {
                    state.navigation[0] = `${root}/${prevNav.appLink}/${prevNav.pageLink}`
                }
                if (prevNav.dataLink) {
                    state.navigation[0] = `${state.navigation[0]}/${prevNav.dataLink}`
                    state.navigation[1] = `${state.navigation[0]}/${prevNav.dataLink}`
                }
            }
            const modePages = new Set([PageType.Floorplan, PageType.Gallery, PageType.Neighbourhood, PageType.Building, PageType.Sitemap])
            if (prevNav.appLink != null && prevNav.pageLink != null && modePages.has(prevNav.pageLink)) {
                state.compareMode = prevNav.pageLink
            } else {
                state.compareMode = ''
                // Reset to info
                if (prevNav.appLink != null && prevNav.pageLink != null) {
                    if (state.customDomain.app) {
                        state.navigation[0] = root
                        state.navigation[1] = root
                    } else {
                        state.navigation[0] = `${root}${prevNav.appLink}`
                        state.navigation[1] = `${root}${prevNav.appLink}`
                    }
                }
            }

            if (state.navigation[0] == null) {
                state.navigation[0] = [root, state.customDomain.app ? null : prevNav.appLink, state.compareMode].filter((x) => x != null && x.length > 0).join('/')
            }
            if (state.navigation[1] == null) {
                state.navigation[1] = [root, state.customDomain.app ? null : prevNav.appLink, state.compareMode].filter((x) => x != null && x.length > 0).join('/')
            }
        } else {
            state.compareMode = prevNav.pageLink
            if (state.navigation[0] == null) {
                if (customDomain.app) {
                    state.navigation[0] = `${root}${state.compareMode}`
                } else {
                    state.navigation[0] = `${root}${appLink}/${state.compareMode}`

                }
            }
            if (state.navigation[1] == null) {
                if (appLink) {
                    if (state.customDomain.app) {
                        state.navigation[1] = `${root}${state.compareMode}`
                    } else {
                        state.navigation[1] = `${root}${appLink}/${state.compareMode}`
                    }
                } else {
                    state.navigation[1] = state.navigation[0]//`${root}/${appLink}/${state.compareMode}`
                }
            }
        }
        saveAppState(state, true)
    }

    if (state.history[0] !== fixedPath) {
        state.history.unshift(fixedPath)
    }

    if (getWindowLocation().pathname !== state.prevLocation) {
        state.prevLocation = getWindowLocation().pathname
    }
}

/**
 * Apply theming
 */
export function applyTheming(getState, dispatch, organization = null, app = null) {
    try {
        let themeId = null
        let colorSchemeId = null

        if (app) {
            if (app.themeId) {
                themeId = app.themeId
            }
            if (app.colorSchemeId) {
                colorSchemeId = app.colorSchemeId
            }
        }
        if ((!themeId || !colorSchemeId) && organization) {
            if (!themeId && organization.themeId) {
                themeId = organization.themeId
            }
            if (!colorSchemeId && organization.colorSchemeId) {
                colorSchemeId = organization.colorSchemeId
            }
        }
        if (!themeId || !colorSchemeId) {
            const config = getState().app.config
            if (!themeId && config.themeId) {
                themeId = config.themeId
            }
            if (!colorSchemeId && config.colorScheme) {
                colorSchemeId = config.colorScheme
            }
        }

        if ((!themeId || !colorSchemeId) && getState().app.config.organizations) {
            const homegyde = getState().app.config.organizations.find((x) => x.link === 'homegyde')
            if (!homegyde) {
                throw new Error('Homegyde org not found')
            }
            if (!themeId && homegyde.themeId) {
                themeId = homegyde.themeId
            }
            if (!colorSchemeId && homegyde.colorSchemeId) {
                colorSchemeId = homegyde.colorSchemeId
            }
        }

        if (themeId) {
            dispatch(applyTheme(themeId))
        }
        if (colorSchemeId) {
            dispatch(applyColorScheme(colorSchemeId))
        }
    } catch (e) {
        logger.error('Failed to apply themes, applying default', e)
        dispatch(applyTheme('homegyde'))
        dispatch(applyColorScheme('homegyde'))
    }
}

/**
 * Apply query
 */
function applyQueryHelper(state: AppState, query: Dict, type: QueryType) {
    const newQuery = { ...state.queries }
    switch (type) {
        case QueryType.Projects: {
            const orgPrev = ProjectFilter.Organization in newQuery[QueryType.Projects]
            // if (Object.keys(query).length > 0) {
            // pushHistory(state, `/${PageType.Developments}`)
            // }
            newQuery[QueryType.Projects] = { ...query }
            if (query.beds) {
                newQuery[QueryType.Floorplans].beds = query.beds
            }
            if (query.sqft) {
                newQuery[QueryType.Floorplans].sqft = query.sqft
            }
            if (query.price) {
                newQuery[QueryType.Floorplans].price = query.price
            }
            state.queries = newQuery
            const orgNew = ProjectFilter.Organization in newQuery[QueryType.Projects]
            if (orgPrev || orgNew) {
                calculateProjectRanges(state)
            }
            break
        }
        default:
            newQuery[type] = { ...query }
            state.queries = newQuery
            break
    }
    // hashQueries(state)
}

/**
 * Async actions
 */
//  Neither implicit or explicit typing not working here for some reason
// export const getApp:AsyncThunk<string, string, {
//     state: RootState;
//     rejectValue: ValidationErrors;

/**
 * Retrieve overall app configuration data
 */
export const retrieveConfig = createAsyncThunk<Config, string>(
    'app/retrieveConfig',
    async (string, { rejectWithValue, getState, dispatch }) => {
        const options = {
            ...getState().app.analytics,
            resolution: `${window.innerWidth}x${window.innerHeight}`,
            userAgent: getState().app.screen.userAgent.substring(0, 500),
        }
        const tryConfig = () => {
            return getConfig(options)
                .then((x) => {
                    if (!x || !x.version || (typeof x == 'object' && Object.keys(x).length == 0)) {
                        throw new Error('Failed to retrieve config')
                    }
                    // Check for forced clear in window url (?clear=1)
                    if (getWindowLocation().search.indexOf('clear=1') > -1) {
                        clearCache(true)
                        getWindowLocation().href = getWindowLocation().href.replace('?clear=1', '')
                        return x
                    } else {
                        const newUrl = checkVersion(x.version)
                        if (newUrl) {
                            getWindowLocation().href = newUrl
                        }
                    }

                    // Immediately remove versioning
                    /*setTimeout(() => {
                        history.replace({
                            search: '',
                            hash: getWindowLocation().hash
                        })
                    }, 0)*/
                    return x
                })
        }
        return retryOperation(tryConfig)
            .catch(rejectWithValue)
    },
)

/**
 * Initialize theming
 */
export const initializeTheming = createAsyncThunk<Config, string>(
    'app/initializeTheming',
    async (string, { rejectWithValue, dispatch, getState }) => {
        applyTheming(getState, dispatch)
    }
)

/**
 * Initialize a single app, retrieving all essential data
 */
export const initializeStandaloneProject = createAsyncThunk<AppState,
    { appLink: string, organizationLink: string }>(
        'app/initializeStandaloneProject',
        async ({ appLink, organizationLink }, { dispatch, getState, rejectWithValue }) => {
            // Pre-emptively apply theming
            const tryApp = () => {
                const app = getState().app.config.apps.find((x) => x.meta.link == appLink)
                if (app && (!getState().app.customDomain.app || getState().app.standaloneApp)) {
                    applyTheming(getState, dispatch, null, app.meta)
                }

                const options = {
                    standalone: getState().app.standaloneApp ? 1 : 0
                }

                return getApp(appLink, organizationLink, false, false, getState().app.salescenter)
                    .then((x) => {
                        if (!x || !x.meta) {
                            logger.error('Error loading app', x)
                            throw new Error('Error loading app')
                        }
                        let org = null
                        if (x.organizations && x.organizations.length > 0) {
                            org = getState().app.config.organizations.find((y) => y.id == x.organizations[0])
                        }
                        if (!getState().app.customDomain.app || getState().app.standaloneApp) {
                            applyTheming(getState, dispatch, org, x.meta)
                        }
                        // dispatch(appSlice.actions.applyTheme(x.meta.themeId))
                        // dispatch(appSlice.actions.applyColorScheme(x.meta.colorSchemeId))
                        return x
                    })
            }
            return retryOperation(tryApp)
                .catch(rejectWithValue)
        },
    )

/**
 * Retrieve single app, for viewing additional information
 */
export const initializeProject = createAsyncThunk<AppState,
    { appLink: string, organizationLink: string, splitIndex: string }>(
        'app/initializeProject',
        async ({ appLink, organizationLink }, { getState, rejectWithValue }) => {
            const tryApp = () => getApp(appLink, organizationLink, false, false, getState().app.salescenter)
                .then((x) => {
                    if (!x || !x.meta) {
                        throw new Error('Error loading app')
                    }
                    return x
                })
            return retryOperation(tryApp)
                .catch(rejectWithValue)
        }
    )

/**
 * Retrieve app floorplans for user
 */
export const retrieveFavourites = createAsyncThunk<AppState, { appIds: string, organization: OrganizationData }>(
    'app/retrieveFavourites',
    async ({ appIds, organization }, { dispatch, getState, rejectWithValue }) => {
        const tryFavourites = () => {
            if (getState().app.standaloneApp && getState().app.projectCurrent[0]) {
                appIds = [getState().app.projectCurrent[0].meta.id]
            }
            return getFavourites(appIds, organization)
                .then((x) => {
                    if (!x) {
                        logger.error('Error loading floorplans', x)
                        throw new Error('Error loading floorplans')
                    }
                    return x
                })
        }
        return retryOperation(tryFavourites)
            .catch(rejectWithValue)
    }
)

/**
 * Retrieve app upgrades
 */
export const retrieveUpgrades = createAsyncThunk<UpgradeView[], void, { state: RootState }>(
    'app/retrieveUpgrades',
    (app, { rejectWithValue }) => {
        const tryUpgrades = () => getUpgrades(app)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                // Parse upgrades
                return ({
                    ...x, data: setupUpgrades({ ...x.data })
                })
            })
        return retryOperation(tryUpgrades)
            .catch(rejectWithValue)
    }
)

export const updateUpgradeProduct = createAsyncThunk<UpgradeProductData[], void, { state: RootState }>(
    'app/updateUpgradeProduct',
    ({ app, upgradeProduct }, { rejectWithValue }) => {
        const tryUpgrades = () => putUpgradeProduct(app, upgradeProduct)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                return x
            })
        return retryOperation(tryUpgrades)
            .catch(rejectWithValue)
    }
)

export const logUpgrade = createAsyncThunk<UpgradeProductData[], void, { state: RootState }>(
    'app/logUpgrade',
    ({ app, log }, { rejectWithValue }) => {
        const tryUpgrades = () => postUpgradeLog(app, log)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                return x
            })
        return retryOperation(tryUpgrades)
            .catch(rejectWithValue)
    }
)

export const retrieveUpgradeLogs = createAsyncThunk<UpgradeLogData[], void, { state: RootState }>(
    'app/logUpgrade',
    ({ app, user, upgradeView }, { rejectWithValue }) => {
        const tryUpgrades = () => getUpgradeLogs(app, user, upgradeView)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                return x
            })
        return retryOperation(tryUpgrades)
            .catch(rejectWithValue)
    }
)


export const notifyUpgrade = createAsyncThunk<UpgradeProductData[], void, { state: RootState }>(
    'app/notifyUpgrade',
    ({ app, organization, data }, { dispatch, rejectWithValue }) => {
        const tryUpgrades = () => postUpgradeNotify(app, organization, data)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                if (x?.failures.length > 0) {
                    throw new Error(`Failed to notify certain users, failures: ${x.failures}, success: ${x.sends}`)
                }
                dispatch(appSlice.actions.resolvePending({ success: true, message: `${x.sends.length} notification${x.sends.length > 1 ? 's' : ''} sent.` }))
                return x
            })
        return retryOperation(tryUpgrades)
            .catch((x) => {
                dispatch(appSlice.actions.resolvePending(x))
                rejectWithValue(x)
            })
    }
)

export const completeUpgrade = createAsyncThunk<UpgradeProductData[], void, { state: RootState }>(
    'app/completeUpgrades',
    ({ app, organization, data }, { dispatch, rejectWithValue }) => {
        const tryUpgrades = () => postUpgradeComplete(app, organization, data)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                dispatch(appSlice.actions.resolvePending(true))
                return x
            })
        return retryOperation(tryUpgrades)
            .catch((x) => {
                dispatch(appSlice.actions.resolvePending(x))
                rejectWithValue(x)
            })
    }
)

export const retrieveTemporaryUpgradeOrder = createAsyncThunk<UpgradeView[], void, { state: RootState }>(
    'app/retrieveTemporaryUpgradeOrder',
    (app, { rejectWithValue, getState }) => {
        const tryUpgrades = () => getUpgradeOrder(app, getState().user.temporary ? getState().user.temporary : null)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                return x
            })
        return retryOperation(tryUpgrades)
            .catch(rejectWithValue)
    }
)

export const retrieveUpgradePdf = createAsyncThunk<string[], void, { state: RootState }>(
    'app/retrieveUpgradePdf',
    ({ app, data }, { dispatch, rejectWithValue }) => {
        const tryUpgrades = () => postUpgradePdf(app, data)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                dispatch(appSlice.actions.resolvePending(true))
                return x
            })
        return retryOperation(tryUpgrades)
            .catch((x) => {
                dispatch(appSlice.actions.resolvePending(x))
                rejectWithValue(x)
            })
    }
)

export const createUpgradeDocument = createAsyncThunk<{ app: AppData, documents: UpgradeDocumentData[] }, void, { state: RootState }>(
    'app/createUpgradeDocument',
    ({ app, documents }, { dispatch, rejectWithValue }) => {
        const tryUpgrades = () => postUpgradeDocument(app, documents)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                dispatch(appSlice.actions.resolvePending(true))
                return x
            })
        return retryOperation(tryUpgrades)
            .catch((x) => {
                dispatch(appSlice.actions.resolvePending(x))
                rejectWithValue(x)
            })
    }
)

export const createUpgradeDocumentDownload = createAsyncThunk<{ app: AppData, documents: UpgradeDocumentData[] }, void, { state: RootState }>(
    'app/createUpgradeDocumentDownload',
    ({ app, documents }, { dispatch, rejectWithValue }) => {
        const tryUpgrades = () => postUpgradeDocumentDownload(app, documents)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                dispatch(appSlice.actions.resolvePending(true))
                return x
            })
        return retryOperation(tryUpgrades)
            .catch((x) => {
                dispatch(appSlice.actions.resolvePending(x))
                rejectWithValue(x)
            })
    }
)

export const retrieveUpgradeLastChange = createAsyncThunk<AppData, void, { state: RootState }>(
    'app/retrieveUpgradeLastChange',
    (app, { dispatch, rejectWithValue }) => {
        const tryUpgrades = () => getUpgradeLastChange(app)
            .then((x) => {
                if (!x.success) {
                    throw new Error(x)
                }
                // dispatch(appSlice.actions.resolvePending(true))
                return x
            })
        return retryOperation(tryUpgrades)
            .catch((x) => {
                // dispatch(appSlice.actions.resolvePending(x))
                rejectWithValue(x)
            })
    }
)
/**
 * Retrieve only critical subset of application information
 */
export const updateApp = createAsyncThunk<
    AppState,
    { appLink: string, organizationLink: string, critical: boolean },
    { state: RootState }
>(
    'app/updateApp',
    async ({ appLink, organizationLink, critical }, { getState, rejectWithValue }) => {
        if (getState().admin.editPending) {
            return rejectWithValue('Skipping update while change in progress')
        }

        const tryApp = () => {
            return getApp(appLink, organizationLink, critical)
                .then((x) => {
                    if (!x || !x.meta) {
                        logger.error('Error updating app', x)
                        throw new Error(ErrorMessage.UpdateError)
                    }
                    return x
                })
        }
        return retryOperation(tryApp)
            .catch(rejectWithValue)
    },
)

/**
 * Initialize the home page of the application
 */
export const initializeHome = createAsyncThunk<AppState, UrlOption>(
    'app/initializeHome',
    (options, { rejectWithValue, getState, dispatch }) => {
        const tryHome = () => {
            return Promise.all([getAllApps(options), getAllMedia(options)])
                .then((x) => {
                    if (!x[0].success || !x[0].apps) {
                        throw new Error(x[0])
                    }
                    if (!x[1].success || !x[1].media) {
                        throw new Error(x[1])
                    }
                    if (!getState().app.links.appLink) {
                        applyTheming(getState, dispatch, x[0].apps.organization)
                    }
                    return x
                })
                .then((x: [AppMetaData[] | { media: MediaData[] }]) => ({ ...x[0].apps, media: x[1].media }))
        }

        return retryOperation(tryHome)
            .catch(rejectWithValue)
    },
)

/**
 * Initialize other page
 */
export const initializeDefault = createAsyncThunk<AppState, string>(
    'app/initializeDefault',
    (x, { getState, dispatch }) => applyTheming(getState, dispatch),
)
/**
 * Send an email of a given type to a given address
 */
export const sendEmail = createAsyncThunk<
    void,
    { app: AppData, emailType: EmailType, email: string, url: string, hash: Dict, options: EmailOptions },
    { state: RootState }
>(
    'app/sendEmail',
    ({
        app,
        emailType,
        email,
        hash,
        url,
        options,
    }, { getState, dispatch, rejectWithValue }) => {
        const tryEmail = async () => {
            const val = validateEmail(email)
            if (val != null) {
                throw new Error(val)
            }
            let finalLink = null
            if (url) {
                finalLink = url
            } else {
                finalLink = getWindowLocation().origin + getWindowLocation().pathname
                if (hash) {
                    const hashString = saveState('user', hash)
                    finalLink = `${finalLink}#${hashString}`
                    let message = `Generated Link: ${finalLink}`
                    logger.info(message)
                }
            }
            let sourceApp = app ? app : getState().app.projectCurrent[0]
            const appId = sourceApp ? sourceApp.meta.id : null
            const organizationId = (getState().app.organization && !getState().app.current) ? getState().app.organization.id : null
            // Pause for dramatic effect
            await new Promise((resolve) => setTimeout(resolve, 500))
            let data = {
                ...options,
                email,
                link: finalLink,
                appId,
                organizationId,
            }
            return postEmail(emailType, data, { domain: getState().app.customDomain.app || getState().app.customDomain.organization })
                .then((x) => {
                    if (x && x.success) {
                        dispatch(appSlice.actions.resolvePending(true))
                    } else {
                        dispatch(appSlice.actions.resolvePending(x))
                    }
                    return x
                })
        }

        return retryOperation(tryEmail)
            .catch((x) => {
                dispatch(appSlice.actions.resolvePending(x))
                rejectWithValue(x)
            })
    },
)
/**
 * Display prompt with given options
 */
let promptTimeout = null
export const showPrompt = createAsyncThunk<boolean, PromptOptions, { state: RootState }>(
    'app/showPrompt',
    async (options, { getState }) => {
        clearTimeout(promptTimeout)
        await new Promise((resolve, reject) => {
            if (!options.type) {
                reject("Missing prompt type")
            }
            const checkResult = () => {
                const { prompt } = getState().app
                if (prompt && prompt.result === null) {
                    promptTimeout = setTimeout(checkResult, 100)
                } else if (prompt && prompt.result !== null) {
                    resolve()
                } else {
                    reject()
                }
            }

            checkResult()
        })
        return getState().app.prompt.result
    },
)

/**
 * Navigation to another page
 * With checks in place to prevent unwanted navigation (e.g. away from edits)
 */
export const navigateAsync = createAsyncThunk<
    { app: AppData, pageType: PageType, options: PageOptions },
    {
        app: AppData, pageType: PageType, options: PageOptions, page: PageData,
        appLink: string, pageLink: string, dataLink: string
    },
    { state: RootState }
>(
    'app/navigateAsync',
    ({ app, pageType, options }, { dispatch, getState }) => {
        return new Promise((resolve, reject) => {
            if (getState().admin.changePending && onAdminPage()) {
                dispatch(showPrompt(PromptOptions.ChangePending))
                    .then((x) => {
                        if (x.payload) {
                            // Clear any changes
                            dispatch(resetDelta())
                            resolve()
                        } else {
                            reject()
                        }
                    })
                    .catch(reject)
            } else {
                resolve()
            }
        }).then(() => {
            const finalOptions = { ...options }
            if (finalOptions.path) {
                dispatch(setPath(finalOptions.path))
            }
            let link = ''
            let finalPage = null
            let baseRoute = null
            const { links, projectPath, rootPath } = getState().app
            // Navigate back to root of organization
            if (finalOptions.root) {
                if (rootPath.length > 1) {
                    baseRoute = fnc.trimTrailingSlash(rootPath)
                } else {
                    baseRoute = ''
                }
            } else {
                // Go to route of project directory
                baseRoute = fnc.trimTrailingSlash(projectPath)
            }

            let appLink, pageLink, dataLink, extraLink = null
            if ((!getState().app.customDomain.app && app)) {
                appLink = app.meta.link
            }

            if (finalOptions.useCompareMode) {
                // Make
                pageType = getState().app.compareMode
            }

            if (finalOptions.dataLink) {
                pageLink = pageType
                dataLink = finalOptions.dataLink
                if (finalOptions.extraLink) {
                    extraLink = finalOptions.extraLink
                }
            } else if (app) {
                // Page link
                pageLink = pageType
                if (app.pages) {
                    finalPage = app.pages.find((x) => x.pageType === pageType)
                    if (finalPage) {
                        pageLink = finalPage.link
                    }
                }

                // Data link
                switch (pageType) {
                    default:
                        break
                    /*case PageType.Sitemap:
                        dataLink = finalOptions.viewLink
                        if (finalOptions.unitId) {
                            // Find floorplan for this unit
                            const unit = app.maps.unitName[finalOptions.unitId]
                            const floorplan = app.maps.floorplanLink[unit.floorplans[0]]
                            if (floorplan) {
                                dataLink = `${dataLink}/${floorplan.link}`
                            }
                        } else if (finalOptions.floorplanId) {
                            const floorplan = app.floorplans.find(
                                (x) => x.id === finalOptions.floorplanId,
                            )
                            if (floorplan != null) {
                                dataLink = `${dataLink}/${floorplan.link}`
                            } else {
                                throw new Error(`Cannot find floorplan ${finalOptions.floorplanId}`)
                            }
                        }
                        break*/
                    case PageType.Sitemap:
                    case PageType.Building:
                    case PageType.Floorplan:
                        if (finalOptions.unitId) {
                            // Find floorplan for this unit
                            const unit = app.maps.unitName[finalOptions.unitId]
                            const floorplan = app.maps.floorplan[unit.floorplans[0].floorplanId]
                            if (floorplan) {
                                dataLink = floorplan.link
                            }
                        } else if (finalOptions.floorplanId != null) {
                            const floorplan = app.floorplans.find(
                                (x) => x.id === finalOptions.floorplanId,
                            )
                            if (floorplan != null) {
                                dataLink = floorplan.link
                            } else {
                                throw new Error(`Cannot find floorplan ${finalOptions.floorplanId}`)
                            }
                        }
                        break
                    case PageType.Modelhome:
                        if (finalOptions.modelhomeId != null) {
                            const modelhome = app.modelhomes.find(
                                (x) => x.id === finalOptions.modelhomeId,
                            )
                            if (modelhome != null) {
                                dataLink = modelhome.link
                            } else {
                                throw new Error(`Cannot find modelhome ${finalOptions.modelhomeId}`)
                            }
                        }
                        break
                }
            } else {
                pageLink = pageType
            }
            if (pageLink == PageType.Admin) {
                link = [baseRoute, pageLink, appLink, dataLink, extraLink].filter((x) => x != null && x.length > 0).join('/')
            } else {
                link = [baseRoute, appLink, pageLink, dataLink, extraLink].filter((x) => x != null && x.length > 0).join('/')
            }
            if (link[0] != '/') {
                link = `/${link}`
            }

            dispatch(setChangePending(false))

            // if (getWindowLocation().pathname === link) {
            // return { pageType, page: finalPage, link: null, options: finalOptions }
            // }

            // Include existing query parameters
            if (!(options && options.splitIndex != null)) {
                const historyObj = {
                    pathname: link,
                    search: window.location.search,
                    hash: window.location.hash,
                }
                if (finalOptions.replaceHistory) {
                    history.replace(historyObj)
                } else {
                    history.push(historyObj)
                }
            }

            return { app, pageType, options: finalOptions, baseRoute, appLink, pageLink, dataLink, link, page: finalPage }
        }).catch((e) => {
            logger.error(e)
            return { app, pageType, options, link: null }
        })
    },
)

/**
 * Navigate backwards through the recorded history
 * Again with checks in place to prevent unwanted navigation (e.g. away from edits)
 */
export const navigateBackAsync = createAsyncThunk<
    PageOptions,
    PageOptions,
    { state: RootState }
>(
    'app/navigateBackAsync',
    async (options, { dispatch, getState, rejectWithValue }) => {
        const state = getState().app
        return new Promise((resolve, reject) => {
            if (getState().admin.changePending && onAdminPage()) {
                dispatch(showPrompt(PromptOptions.ChangePending))
                    .then((x) => {
                        if (x.payload) {
                            // Clear any changes
                            dispatch(resetDelta())
                            resolve()
                        } else {
                            reject()
                        }
                    })
                    .catch(reject)
            } else {
                resolve()
            }
        }).then(() => {
            if (!options || options.splitIndex == null) {
                if (state.history.length > 1) {
                    history.push(state.history[state.history.length - 2])
                }
            } else {
                // Split
                if (appLink == null && state.projectCurrent[options.splitIndex] != null) {
                    state.projectCurrent[options.splitIndex] = null
                }

            }
            return options
        }).catch(rejectWithValue)
    },
)

/**
 * Basic navigate, ignores history
 */
export const navigate = createAsyncThunk<void, string>(
    'app/navigate',
    (link) => {
        history.push(link)
    },
)

/**
 * Navigate to a feature for all comparables
 */
export const navigateFeature = createAsyncThunk<void, string>(
    'app/navigateFeature',
    (pageType, { dispatch, getState }) => {
        const current = getState().app.projectCurrent
        for (let i = 0; i < current.length; i += 1) {
            if (current[i]) {
                dispatch(navigateAsync({ app: current[i], pageType, options: { splitIndex: i } }))
            }
        }
        dispatch(setCompareMode(pageType))
        // return pageType
    }
)


/**
 * Record analytics
 */
export const recordAnalytics = createAsyncThunk<void,
    { type: Analytics, data: EmailAnalytics | AvailabilityAnalytics | FloorplanAnalytics },
    { state: RootState }>(
        'app/recordAnalytics',
        ({ type, data }, { getState, rejectWithValue }) => {
            const state = getState()
            if (data.app) {
                data.appId = data.app.meta.id
            } else {
                data.appId = getState().app.projectCurrent[0]?.meta.id
            }
            data.organizationId = getState().app.organization?.id

            const { app, ...analyticsData } = data
            const tryAnalytics = () => {
                return postAnalytics(type, analyticsData)
            }
            return retryOperation(tryAnalytics)
                .catch(rejectWithValue)
        },
    )

/**
 * Retrieve media
 */
export const retrieveMedia = createAsyncThunk<MediaData[], void, { state: RootState }>(
    'app/retrieveMedia',
    (options, { rejectWithValue }) => {
        const tryMedia = () => {
            return getAllMedia(options)
                .then((x) => {
                    if (x && x.success && x.media) {
                        return x
                    } else {
                        throw new Error(x)
                    }
                })
        }

        return retryOperation(tryMedia)
            .catch(rejectWithValue)
    }
)

/**
 * Submit form
 */
export const submitBooking = createAsyncThunk<Dict,
    { app: AppData, data: Dict, options: Dict }, { state: RootState }>(
        'app/submitBooking',
        ({ app, data, options }, { getState, dispatch, rejectWithValue }) => {
            const tryBooking = () => {
                if (data.message) {
                    if (!Array.isArray(data.message)) {
                        data.message = [data.message]
                    }
                } else {
                    data.message = []
                }
                let tags = []
                if (options.analyticsEventTypeId) {
                    tags.push(AnalyticsEvent[options.analyticsEventTypeId]?.toLowerCase())
                }
                if (data.tags) {
                    if (Array.isArray(data.tags)) {
                        tags = [...tags, ...data.tags]
                    } else if (typeof data.tags === 'string') {
                        tags = [...tags, ...data.tags.split(',')]
                    }
                }
                if (app) {
                    tags.unshift(app.meta.link)
                }
                data.tags = tags
                data.message.push(`Tags: ${data.tags.map((x) => `#${x}`).join(' ')}`)
                data.message = `<br/>${data.message.filter((x) => x && x.length > 0).join('<br/>')}`

                // Get hotjar and facebook id
                return postBooking({
                    appId: app ? app.meta.id : null,
                    organizationId: getState().app.organization ? getState().app.organization.id : null,
                    hotjarId: getHotjarUserId(),
                    pageUrl: window.location.href,
                    pageName: document.title,
                    data: data,
                    options: options ? options : {},
                },
                    { domain: getState().app.customDomain.app || getState().app.customDomain.organization }
                )
                    .then((x) => {
                        if (x && x.success) {
                            dispatch(appSlice.actions.resolvePending(true))
                            // if (x.user || x.token) {

                            // dispatch(loadUser(x))
                            // }
                            // Check if we should do a follow up hubspot identification
                            if (x.identifyHubspot && data.email) {
                                hubspotIdentifyUser(data.email)
                            }
                        } else {
                            dispatch(appSlice.actions.resolvePending(x))
                        }
                        return x
                    })
            }

            return retryOperation(tryBooking)
                .catch((x) => {
                    dispatch(appSlice.actions.resolvePending(x))
                    return rejectWithValue(x)
                })
        }
    )
/**
 * Slice +  sync actions/reducers
 */
// TODO, typescript can't seem to detect the payload type, so sites of errors
const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setInitialized(state: AppState, action: PayloadAction<number>) {
            state.initialized = action.payload
            if (state.initialized <= 1) {
                state.current = null
            }
        },
        // Query operations
        applyQuery(
            state: AppState,
            action: PayloadAction<{
                query: Query,
                type: QueryType,
            }>,
        ) {
            const { query, type } = action.payload
            applyQueryHelper(state, query, type)
            saveAppState(state, true)
        },
        resetQuery(
            state: AppState,
            action: PayloadAction<{
                key: string
                type: QueryType,
                value: string | number,
            }>,
        ) {
            const { key, type, value } = action.payload
            const newQuery = resetQueryHelper(type, state.queries[type], key, value)
            applyQueryHelper(state, newQuery, type)
            saveAppState(state, true)
        },
        // Query operations
        setQuery(
            state: AppState,
            action: PayloadAction<{
                query: Query,
                type: QueryType,
            }>,
        ) {
            const { query, type } = action.payload
            const newQuery = setQueryHelper(type, state.queries[type], query)
            applyQueryHelper(state, newQuery, type)
            saveAppState(state, true)
        },
        toggleQuery(
            state: AppState,
            action: PayloadAction<{
                query: Query,
                type: QueryType,
            }>,
        ) {
            const { query, type } = action.payload
            const newQuery = toggleQueryHelper(type, state.queries[type], query)
            applyQueryHelper(state, newQuery, type)
            saveAppState(state, true)
        },
        applyFixedQuery(
            state: AppState,
            action: PayloadAction<{
                query: Query,
                type: QueryType,
            }>,
        ) {
            const { query, type } = action.payload
            state.fixedQueries[type] = query
            saveAppState(state, true)
        },
        // Handlw when browser forward/back is used
        popBack(state: AppState) {
            setupHistory(state)
        },
        // End compare mode
        stopCompare(state: AppState) {
            state.compare = false
            state.compareCommunities = false
            state.navigation = [state.navigation[0], null]
            history.push(state.navigation[0])

            state.links = parseNav(getWindowLocation().pathname, state.organization, state)
            // setupHistory()

            if (getWindowLocation().pathname !== state.prevLocation) {
                state.prevLocation = getWindowLocation().pathname
            }
        },
        // Push ui notification
        pushNotification(state: AppState, action: PayloadAction<string>) {
            const { id, message, status } = action.payload
            const idx = id != null ? state.notifications.findIndex((x) => x.id == id) : -1
            let notification = null
            if (idx != -1) {
                state.notifications[idx] = { ...notifications[idx], id, message, status }
                notification = state.notifications[idx]
            } else {
                notification = { id: state.notifications.length + 1, message, status }
                state.notifications.push(notification)
            }
            action.payload.id = notification.id
            return state
        },
        removeNotification(state: AppState, action: PayloadAction<string>) {
            const id = action.payload
            const idx = state.notifications.findIndex((x) => x.id == id)
            if (idx != null) {
                // state.notifications.splice(idx, 1)
                state.notifications.status == NotificationStatus.Archive
            }
            return state
        },
        // Set app to fullscreen mode
        toggleFullscreen(state: AppState, action: PayloadAction<boolean>) {
            state.fullscreen = !state.fullscreen
        },
        // Encode state and return as link with # code
        getStateLink(state: AppState, action) {
            const { fixFavourites } = action.payload
            if (getAppUrl() != null) {
                // return `${getAppUrl()}${state.projectPath}/${state.navigation[0]}#${saveAppState(state)}`
            }

            // Fix favourites to point to search
            let href = getWindowLocation().href.split('#')[0]
            if (fixFavourites && href.includes('/favourites')) {
                href = href.replace('/favourites', '/search')
            }

            const fixNav = (slice: AppState) => {
                for (let i = 0; i < slice.navigation.length; i += 1) {
                    if (slice.navigation[i] != null && slice.navigation[i].includes('/favourites')) {
                        slice.navigation[i] = slice.navigation[i].replace('/favourites', '/search')
                    }
                }
                return slice
            }

            action.payload = `${href}#${saveAppState(state, false, fixNav)}`
        },
        // User agrees to cookie aggrement
        agreeToCookies(state: AppState) {
            state.cookies = true
            // fnc.setCookie('cookieConsent', 'true', 365)
            // fnc.setCookie('__hs_opt_out', 'no')
            // fnc.setCookie('__hs_initial_opt_in', 'yes')
            saveAppState(state)
        },
        applyStateCookies(state: AppState) {
            applyCookies(state)
        },
        // Conclude prompt with a value
        resolvePrompt(state: AppState, action: PayloadAction<string | boolean>) {
            if (state.prompt) {
                state.prompt.result = action.payload
            }
        },
        // Conclude a prompt that is pending and awaiting result
        resolvePending(state: AppState, action: PayloadAction<string | boolean>) {
            if (state.prompt) {
                if (typeof action.payload == 'object' && action.payload.message && state.prompt.successMessage == null) {
                    state.prompt.successMessage = action.payload.message
                }
                state.prompt.pendingResult = action.payload
            }
        },
        // Apply color scheme to page varables
        applyColorScheme(state: AppState, action: PayloadAction<string>) {
            const { payload } = action
            if (payload == null) {
                logger.error('Invalid color scheme')
                return
            }

            // Grab theme from state
            if (!state.config.colorSchemes) {
                logger.error('Missing color scheme config')
                return
            }

            const colorScheme: ColorScheme = state.config.colorSchemes
                .find((x: ColorScheme) => x.id === payload)
            state.config.colorScheme = colorScheme.id
            if (colorScheme != null) {
                const keys = Object.keys(colorScheme)
                const colors = Object.values(colorScheme)
                // Start at 2: skip id and name
                for (let i = 2; i < keys.length; i += 1) {
                    const hex: string = colors[i]
                    if (hex) {
                        const rgb = fnc.hexToRgb(hex)
                        const avgColor = (rgb.r + rgb.g + rgb.b) / 3
                        // Convert camel case to hyphen separated
                        const label = fnc.camelToSnakeCase(keys[i].replace('Color', '')).replace(/_/g, '-')
                        if (hex != null) {
                            switch (label) {
                                case 'id':
                                case 'name':
                                    break
                                case 'primary':
                                case 'secondary':
                                case 'tertiary':
                                    fnc.setRootProperty(`--${label}-mid`, `#${hex}`)
                                    fnc.setRootProperty(`--${label}-light`, fnc.adjustHex(hex, 20))
                                    fnc.setRootProperty(`--${label}-dark`, fnc.adjustHex(hex, -20))
                                    break
                                case 'button-text':
                                case 'button-alt-text':
                                case 'button-no-bg-text':
                                    if (avgColor > 255 / 2.0) {
                                        fnc.setRootProperty(`--${label}-hover`, fnc.adjustHex(hex, 20))
                                    } else {
                                        fnc.setRootProperty(`--${label}-hover`, fnc.adjustHex(hex, -100))
                                    }
                                    fnc.setRootProperty(`--${label}`, `#${hex}`)
                                    break
                                default:
                                    fnc.setRootProperty(`--${label}`, `#${hex}`)
                                    break
                            }
                        }
                    }
                }
            } else {
                logger.error(`Could not find color scheme ${payload}`)
            }
        },
        // Apply applicaiton theme to DOM
        applyTheme(state: AppState, action: PayloadAction<string>) {
            const { payload } = action
            if (payload == null) {
                logger.error('App has no theme')
                return
            }
            const theme = state.config.themes.find((x) => x.id === payload)
            if (theme != null) {
                state.config.theme = theme.link
                const baseTheme = theme.baseThemeId
                    ? state.config.themes.find((x) => x.id === theme.baseThemeId)
                    : null
                if (baseTheme != null) {
                    state.config.baseTheme = baseTheme.name
                }

                const app = document.getElementById('app')
                const themeName = `${state.config.theme}${state.config.baseTheme ? `-${state.config.baseTheme}` : ''}`
                app.setAttribute('theme', themeName)
            } else {
                logger.error('Failed to locate theme')
            }
        },
        // Handler for screen resizing
        resize(state: AppState) {
            try {
                let size: ScreenSize = parseInt(fnc.getRootProperty('--screen-size'), 10)
                let orientation: ScreenOrientation = parseInt(fnc.getRootProperty('--screen-orientation'), 10)
                if (!isMobile) {
                    size = ScreenSize.Desktop
                }

                state.screen = { ...state.screen, size, orientation, isMobile: fnc.isMobileDevice(), isTouch: fnc.isTouchDevice(), userAgent: window.navigator.userAgent }

                // Apply full screen support
                if (state.screen.fullscreenSupport == null) {
                    let supported = true
                    for (let i = 0; i < fullscreenExclusions.length; i += 1) {
                        if (state.screen.userAgent.includes(fullscreenExclusions[i])) {
                            supported = false
                            break
                        }
                    }
                    state.screen.fullscreenSupport = supported && !state.isMobile
                }
            } catch (e) {
                logger.error('Failed to get screen size')
            }
        },
        // Edit app
        editApp(state: AppState, action: PayloadActioni<{ view: AdminView, delta: AdminDelta }>) {
            const { view, delta } = action.payload
            switch (view) {
                default:
                    break
                case AdminView.Availability:
                    for (let i = 0; delta && i < delta.length; i += 1) {
                        try {
                            const unit = state.current.units.find((x) => x.id === delta[i].unitId)
                            unit.availabilityStateId = delta[i].availabilityStateId
                        } catch (e) {
                            logger.error('Error updating units', e)
                        }
                    }
                    break
                case AdminView.Pricing:
                    for (let i = 0; delta && i < delta.length; i += 1) {
                        try {
                            const floorplan = state.current.floorplans
                                .find((x) => x.id === delta[i].floorplanId)
                            const variation = floorplan.variations
                                .find((x) => x.id === delta[i].variationId)
                            variation.price = delta[i].price
                        } catch (e) {
                            logger.error('Error updating floorplans', e)
                        }
                    }
                    break
            }
        },
        setPath(state: AppState, action: PayloadAction<{ organizationLink: string, appLink: string, baseRoute: string }>) {
            let { organizationLink, appLink, baseRoute } = action.payload
            if (organizationLink == null && state.organization != null && state.organization.link != 'homegyde') {
                organizationLink = state.organization.link
            }

            // Strip out links when using custom domain
            if (state.customDomain.app) {
                organizationLink = null
                appLink = null
                state.projectPath = getPath(organizationLink, null, baseRoute)
                state.rootPath = getPath(organizationLink)
            } else if (state.customDomain.organization) {
                organizationLink = null
                state.projectPath = getPath(organizationLink, null, baseRoute)
                state.rootPath = getPath(organizationLink)
            } else if (state.standaloneApp) {
                state.projectPath = getPath(null, null, null)
                state.rootPath = getPath(null)
            } else {
                state.projectPath = getPath(organizationLink, null, baseRoute)
                state.rootPath = getPath(organizationLink)
            }
        },
        clearCurrent(state: AppState,
            action: PayloadAction<{ organizationLink: string, appLink: string }>) {
            const organizationLink = state.organization ? state.organization.link : null
            state.current = null
            state.projectCurrent = [null, null]
        },
        rebuildHistory(state: AppState, action: PayloadAction) {
            setupHistory(state, action.payload)
            // if (state.initialized === 2) {
            // setupHistory(state, action.payload)
            // }
        },
        setCompareMode(state: AppState, action: PayloadAction<PageType>) {
            state.compareMode = action.payload
        },
        toggleMobileMode(state: AppState) {
            if (state.mobileMode === ListStyle.Grid) {
                state.mobileMode = ListStyle.Map
                localStorage.setItem('mobile-mode', ListStyle.Map)
            } else {
                state.mobileMode = ListStyle.Grid
                localStorage.setItem('mobile-mode', ListStyle.Grid)
            }
        },
        toggleShowBuilding(state: AppState, action) {
            state.showBuilding = !state.showBuilding
        },
        toggleShowSitemap(state: AppState, action) {
            state.showSitemap = !state.showSitemap
        },
        addProjectMedia(state: AppState, action) {
            addMedia(state, action.payload)
        },
        updateProjectMedia(state: AppState, action) {
            const data = action.payload
            if (data.id in state.media) {
                state.media[data.id] = data
            }
        },
        deleteProjectMedia(state: AppState, action) {
            const media = action.payload
            media.forEach((x) => {
                if (x in state.media) {
                    delete state.media[x]
                }
            })
        },
        setIdle(state: AppState, action) {
            state.idle = action.payload
        },

        analyticsEvent(state: AppState, action) {
            const type = action.payload
            if (type != null && state.organization != null) {
                // See if organization has this type
                const configuredEvent = state.organization.analyticsEvents.find((x) => x.analyticsEventTypeId == type)
                if (configuredEvent) {
                    switch (type) {
                        case AnalyticsEvent.RequestInformation:
                        case AnalyticsEvent.EventRegistration:
                        case AnalyticsEvent.ProjectRegistration:
                        case AnalyticsEvent.FillBooking:
                            if (configuredEvent.googleTagId && configuredEvent.googleConversionId) {
                                googleTagReportConversion(`organization_${state.organization.link}`, configuredEvent.googleTagId, configuredEvent.googleConversionId)
                            }
                            break
                        default:
                            break
                    }
                }
            }
            facebookPixelRecordEvent(AnalyticsEvent[type], {
                facebookId: state.analytics.facebookId || getFacebookTrackingId(),
                googleId: state.analytics.googleId || getGoogleTrackingId(),
            })
        },
        navigateBack(state: AppState, action) {
            window.dispatchEvent(new CustomEvent(CustomEventType.HistoryBack))
        },
        setStandaloneApp(state: AppState, action) {
            state.standaloneApp = action.payload
            saveAppState(state)
        },
        setOrganizationVisited(state: AppState, action) {
            state.organizationVisited = action.payload
        },
        updatePrompt(state: AppState, action) {
            if (state.prompt) {
                state.prompt = { ...state.prompt, ...action.payload }
            }
        },
        setScreenFocus(state: AppState, action) {
            state.screen.focus = action.payload
        },
        updateAppContent(state: AppState, action) {
            if (state.projectCurrent[0]) {
                const app = state.projectCurrent[0]
                const idx = app.dynamicContent.findIndex(x => x.id === action.payload.id)
                if (idx >= 0) {
                    app.dynamicContent[idx] = action.payload
                } else {
                    app.dynamicContent.push(action.payload)
                }
                state.projectCurrent[0] = { ...app }
            }
        },
        removeAppContent(state: AppState, action) {
            if (state.projectCurrent[0]) {
                const app = state.projectCurrent[0]
                state.projectCurrent[0] = { ...app, dynamicContent: app.dynamicContent.filter(x => x.id !== action.payload.id) }
            }
        },
        updateAppLocation(state: AppState, action) {
            if (state.projectCurrent[0]) {
                const app = state.projectCurrent[0]
                const idx = app.locations.findIndex(x => x.id === action.payload.id)
                if (idx >= 0) {
                    app.locations[idx] = action.payload
                } else {
                    app.locations.push(action.payload)
                }
                state.projectCurrent[0] = { ...app }
            }
        },
        removeAppLocation(state: AppState, action) {
            if (state.projectCurrent[0]) {
                const app = state.projectCurrent[0]
                app.locations = app.locations.filter(x => x.id !== action.payload.id)
            }
        },
        updateGallery(state: AppState, action) {
            const { gallery } = action.payload
            const app = state.projectCurrent[0]
            const idx = app.galleries.findIndex(x => x.id === gallery.id)
            if (idx != -1) {
                const newGalleries = [...app.galleries]
                newGalleries[idx] = gallery
                state.projectCurrent[0] = { ...app, galleries: newGalleries }
            }
            // TODO, support builders and organizations
        },
        updateProjectCurrent(state: AppState, action) {
            const project = action.payload
            const idx = state.projectCurrent.findIndex(x => x?.id === project?.id)
            if (idx != -1) {
                state.projectCurrent[idx] = project
            }
        }
    },
    extraReducers: (builder) => {
        // Apply configuration to state
        builder.addCase(retrieveConfig.pending, (state: AppState) => {
            state.initialized = 0
            state.error = null
        })
        builder.addCase(
            retrieveConfig.fulfilled,
            (state: AppState, action: PayloadAction<Config>) => {
                const {
                    themes,
                    colorSchemes,
                    organizations,
                    projectStatuses,
                    projectTypes,
                    menuTypes,
                    mediaTags,
                    pageTypes,
                    galleries,
                    version,
                    groups,
                    analyticsEvents,
                    referralSources,
                } = action.payload
                state.config = {}
                if (themes && colorSchemes) {
                    state.config = {
                        themes,
                        colorSchemes,
                        theme: themes[0].name,
                        baseTheme: null,
                        colorScheme: colorSchemes[0].id,
                    }
                } else {
                    logger.error('Missing themes and colorschemes')
                }

                state.config.organizations = organizations
                // Create map for apps to find their organization
                state.config.projectStatuses = fnc.objIdMap(projectStatuses)
                state.config.projectTypes = fnc.objIdMap(projectTypes)
                state.config.menuTypes = fnc.objIdMap(menuTypes)
                state.config.pageTypes = fnc.objIdMap(pageTypes)
                state.config.mediaTags = mediaTags
                state.config.galleries = galleries
                state.config.apps = []
                state.config.builders = []
                state.config.groups = groups
                state.config.version = version
                state.config.version.code = version ? `${version.major}.${version.minor}.${version.build}` : 'unknown'
                state.config.analyticsEvents = analyticsEvents,
                    state.config.referralSources = referralSources
                state.initialized = 1
            },
        )
        builder.addCase(retrieveConfig.rejected, (state: AppState, action) => {
            state.error = handleError(action.payload, action, 'A')
        })
        // Disable app during fetch
        builder.addCase(initializeStandaloneProject.pending, (state: AppState,
            action: { meta: { arg: { organizationLink: string, appLink: string } } }) => {
            // state.initialized = 1
            state.error = null
            // state.projectPath = getPath(action.meta.arg.organizationLink)
            // state.rootPath = getPath(action.meta.arg.organizationLink)
            state.standaloneApp = true
            state.organization = state.config.organizations.find((x) =>
                x.link === action.meta.arg.organizationLink)

        })
        // Apply retrieved AppState
        builder.addCase(
            initializeStandaloneProject.fulfilled,
            (state: AppState, action: PayloadAction<AppState>) => {
                const { payload } = action
                state.projectCurrent = [...state.projectCurrent]
                state.projectCurrent[0] = setupApp(payload, true, state.config)
                state.initialized = 3
                document.title = state.projectCurrent[0].meta.name

                // Add builder
                if (payload.builder) {
                    const idx = state.config.builders.findIndex((x) => x.id == payload.builder.id)
                    state.config.builders = [...state.config.builders]
                    if (idx == -1) {
                        state.config.builders.push(payload.builder)
                    } else {
                        state.config.builders[idx] = payload.builder
                    }
                }

                // if (!payload.critical) {
                addMedia(state, payload.media)
                // }
                // state.initialized = 2

                // Set favicon
                const project = state.projectCurrent[0]
                let faviconId
                const faviconOptions = {}
                if (project.meta.faviconMediaId && project.meta.faviconMediaId in state.media) {
                    faviconId = state.projectCurrent[0].meta.faviconMediaId
                    faviconOptions.app = project
                }

                state.organization = state.config.organizations.find((x) => x.id == project.meta.organizations[0])

                if (!faviconId && project.meta.organizations.length > 0) {
                    if (state.organization.faviconMediaId && state.organization.faviconMediaId in state.media) {
                        faviconId = state.organization.faviconMediaId
                        faviconOptions.organization = state.organization
                    }
                }

                if (faviconId) {
                    const faviconMedia = state.media[faviconId]
                    const url = getMediaLink(faviconMedia.link, faviconOptions)
                    fnc.changeFavicon(url)
                } else {
                    logger.error('Missing favicon')
                }
                // } catch (e) {
                //     logger.error(e)
                //     state.error = handleError(e, action, 'B')
                // }
                saveAppState(state)
            },
        )
        // Failed to retrieve app, show error
        builder.addCase(
            initializeStandaloneProject.rejected,
            (state: AppState, action: PayloadAction<Error>) => {
                state.error = handleError(action.payload, action, 'C')
            },
        )
        // Retrieve app
        builder.addCase(
            initializeProject.pending,
            (state: AppState, action) => {
                // state.standaloneApp = false
                state.error = null
            },
        )
        // Apply retrieved AppState
        builder.addCase(
            initializeProject.fulfilled,
            (state: AppState, action: PayloadAction<AppState>) => {
                // Find in list of apps
                const { success, status, media, ...info } = action.payload
                const splitIndex = action.meta.arg.splitIndex != null ? action.meta.arg.splitIndex : 0
                // const appIndex = state.config.apps.findIndex((x) => x.meta.id == info.meta.id)
                // if (appIndex !== -1) {
                // state.config.apps = [...state.config.apps]
                // state.config.apps[appIndex] = { ...state.config.apps[appIndex], additionalInfo: info }
                // state.config.apps[appIndex] = { ...state.config.apps[appIndex], ...info }
                state.projectCurrent = [...state.projectCurrent]
                state.projectCurrent[splitIndex] = setupApp(info, false, state.config)
                state.initialized = 3
                addMedia(state, media)
                saveAppState(state)
                // }
            },
        )
        builder.addCase(
            initializeProject.rejected,
            (state: AppState, action: PayloadAction<AppState>) => {
                if (action.payload && action.payload.message && action.payload.message.includes('Invalid app link')) {
                    state.error = ErrorMessage.PageNotFound
                } else {
                    state.error = handleError(action.payload, action)
                }
            },
        )
        // Retrieve floorplans
        builder.addCase(
            retrieveFavourites.fulfilled,
            (state: AppState, action: PayloadAction<AppState>) => {
                // Combine apps with state.config.apps
                state.config.favouriteApps = action.payload.apps
                state.config.favouriteApps.forEach(setupAppMaps)
                state.config.favouriteApps.forEach(setupAppMeta)
                if (action.payload.media) {
                    addMedia(state, action.payload.media)
                }
            },
        )
        // Failed to user favourites
        builder.addCase(
            retrieveFavourites.rejected,
            (state: AppState, action: PayloadAction<Error>) => {
                state.error = handleError(action.payload, action, 'D')
            },
        )

        // Apply updated AppState
        builder.addCase(updateApp.fulfilled, (state: AppState, action: PayloadAction<AppState>) => {
            const { payload } = action
            /*if (state.admin.updatePending) {
                logger.error('Update has cancelled poll')
                return
            }*/
            try {
                state.projectCurrent[0] = setupApp(payload, state.current.fullPage)
            } catch (e) {
                state.error = handleError(action.payload, action, 'E')
            }
        })
        // Catch update error
        builder.addCase(updateApp.rejected, (state: AppState, action: PayloadAction<Error>) => {
            logger.error(action.payload)
        })
        // Prepare main page for loading
        builder.addCase(initializeHome.pending, (state: AppState,
            action: { meta: { arg: string } }) => {
            state.initialized = 1
            state.error = null
            // state.standaloneApp = false
            // state.queries = fnc.copyObj(initialQueries)
            state.current = null

            if (!action.meta.arg.domain || action.meta.arg.domain == 'homegyde') {
                state.organization = action.meta.arg.organization ? state.config.organizations.find((x) =>
                    x.link === action.meta.arg.organization) : null
                setupHomeMeta(state)
            }

            state.standaloneOrg = state.organization && state.organization.link != 'homegyde' && state.organization.apps.length == 1
        })
        // Load main page state
        builder.addCase(
            initializeHome.fulfilled,
            (state: AppState, action: PayloadAction<AppState>) => {
                setupHome(state, action.payload)
                addMedia(state, action.payload.media)

                if (action.payload.domain) {
                    state.customDomain = action.payload.domain
                    // Standalone app
                    if (state.customDomain.app) {
                        state.standaloneApp = true
                    } else if (state.customDomain.organization) {
                        state.standaloneOrg = true
                    }
                    setupHomeMeta(state)
                }

                // Setup loaded favicon
                if (state.organization) {
                    if (state.organization.faviconMediaId && state.organization.faviconMediaId in state.media) {
                        const faviconMedia = state.media[state.organization.faviconMediaId]
                        const url = getMediaLink(faviconMedia.link, { organization: state.organization })
                        fnc.changeFavicon(url)
                        return
                    }

                    let defaultId = `${state.organization.link}favicon`
                    if (defaultId in state.media) {
                        const faviconMedia = state.media[defaultId]
                        const url = getMediaLink(faviconMedia.link, { organization: state.organization })
                        fnc.changeFavicon(url)
                        return
                    }
                }
                const homegydeFavicon = 'homegydefavicon'
                if (homegydeFavicon in state.media) {
                    const faviconMedia = state.media[homegydeFavicon]
                    const url = getMediaLink(faviconMedia.link, { organization: state.config.organizations.find((x) => x.link == 'homegyde') })
                    fnc.changeFavicon(url)
                    return
                }
                fnc.changeFavicon('assets/favicon.png')

                saveAppState(state)
            },
        )
        // Failed to load main page
        builder.addCase(
            initializeHome.rejected,
            (state: AppState, action: PayloadAction<Error>) => {
                state.error = handleError(action.payload, action, 'F')
            },
        )

        // Load main page state
        builder.addCase(initializeDefault.fulfilled, (state: AppState) => {
            state.initialized = 2
        })
        // Create prompt
        builder.addCase(
            showPrompt.pending,
            (state: AppState, action: { meta: { arg: PromptData } }) => {
                state.prompt = { ...action.meta.arg }
                state.prompt.result = null
                state.prompt.pendingResult = null
            },
        )
        // Prompt finished
        builder.addCase(showPrompt.fulfilled, (state: AppState) => {
            state.prompt = null
        })
        // Prompt dismissed
        builder.addCase(showPrompt.rejected, (state: AppState) => {
            state.prompt = null
        })

        // Apply navigation
        builder.addCase(
            navigateAsync.pending,
            (state: AppState, action: PayloadAction) => {
                state.error = null
                if (action.meta.arg.baseRoute) {
                    const orgLink = state.organization ? state.organization.link : null
                    state.projectPath = getPath(orgLink, null, action.meta.arg.baseRoute)
                }
            }
        )
        builder.addCase(
            navigateAsync.fulfilled,
            (state: AppState, action: PayloadAction<{
                app: AppData,
                appLink: string,
                pageType: PageType,
                page: PageData,
                link: string,
                options: { [key: string]: string | number }
            }>) => {
                const { pageType, appLink, page, link, options } = action.payload

                if (!link && page && pageType !== PageType.Compare) {
                    logger.error(`Navigation to ${page.name} failed`)
                    return
                }

                switch (pageType) {
                    case (PageType.Compare):
                        break
                    default:
                        // Compare-aware navigation
                        if (options && options.splitIndex != null) {
                            // const nav = link.replace(state.projectPath, '')
                            // state.compareHistory[options.splitIndex].push(nav)
                            state.navigation[options.splitIndex] = link
                            if (action.meta.arg.app == null) {
                                state.projectCurrent[options.splitIndex] = null
                            }
                            saveAppState(state, true)
                        } else {
                            if (pageType === PageType.Favourite) {
                                state.onlyFavourites = true
                            }
                        }
                        break
                }
            },
        )
        // Apply logic to maintain history on back
        builder.addCase(navigateBackAsync.fulfilled, (state: AppState,
            action: PayloadAction<PageOptions>) => {
            const splitIndex = action.payload ? action.payload.splitIndex : null
            if (splitIndex == null) {
                // setupHistory(state)
            } else {
                const history = state.navigation[splitIndex].split('/').filter((x) => x.length > 0)
                if (history.length > 1) {
                    history.pop()
                    // If we're excluding the app, should pop back further
                    if (action.payload.excludeAppPath && history[history.length - 1] == action.payload.appLink) {
                        history.pop()
                    }
                    const newNav = `/${history.join('/')}`
                    state.navigation[splitIndex] = newNav
                }
                // state.compareHistory[splitIndex].pop()
                // const lastIndex = state.compareHistory[splitIndex].length - 1
                // state.navigation[splitIndex] = state.compareHistory[splitIndex][lastIndex]
            }
        })
        // Load media
        builder.addCase(
            retrieveMedia.fulfilled,
            (state: AppState, action: PayloadAction<Media[]>) => {
                addMedia(state, action.payload.media)
            },
        )
        // Failed to load main page
        builder.addCase(
            retrieveMedia.rejected,
            (state: AppState, action: PayloadAction<Error>) => {
                state.error = handleError(action.payload, action, 'G')
            },
        )
        builder.addCase(
            submitBooking.fulfilled,
            (state: AppState, action: PayloadAction<Dict>) => {
                const { user, token } = action.payload
                if (token != null && state.exclusive == false) {
                    state.exclusive = true
                    saveAppState(state)
                }

            }
        )
    },
})

export const {
    setInitialized,
    popBack,
    pushForward,
    stopCompare,
    toggleFullscreen,
    getStateLink,
    agreeToCookies,
    applyTheme,
    applyColorScheme,
    resolvePrompt,
    resolvePending,
    resize,
    resetQuery,
    setQuery,
    applyQuery,
    toggleQuery,
    editApp,
    setPath,
    clearCurrent,
    rebuildHistory,
    setCompareMode,
    toggleMobileMode,
    toggleShowBuilding,
    toggleShowSitemap,
    addProjectMedia,
    updateProjectMedia,
    deleteProjectMedia,
    setIdle,
    applyFixedQuery,
    analyticsEvent,
    navigateBack,
    pushNotification,
    removeNotification,
    setStandaloneApp,
    setOrganizationVisited,
    updatePrompt,
    setScreenFocus,
    applyStateCookies,
    updateAppContent,
    updateAppLocation,
    removeAppLocation,
    removeAppContent,
    updateGallery,
    updateProjectCurrent,
} = appSlice.actions

export default appSlice.reducer
