import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import {
    CustomContentData, CustomContentType, AppData, ThumbSize, AnalyticsEvent, PromptOptions, FloorplanFilter, CustomEventType, ScreenSize, ScreenOrientation, MarkerType, QueryType, PageType, ProjectFilter, MediaType, PromptType, ErrorMessage, UserRole
} from 'app/types'
import {
    Button, DropdownMenu, Icon, IconButton, Media, SlideShow, Map, CollapsibleRow, Tile
} from 'components'
import { Link } from 'react-router-dom'
import { getLinkSelector, getMediaLinkSelector, useAdminPermissions, useAppDispatch, useAppSelector, usePage } from 'app/hooks'
import ReactMarkdown from 'react-markdown'
import { ContentForm, ContentView, NotFound } from 'views/AppPage'
import * as fnc from 'helpers/fnc'
import {
    analyticsEvent, navigateAsync, resetQuery, setQuery, showPrompt, submitBooking,
} from 'actions/appActions'
import { setEditContent } from 'actions/adminActions'
import { LocationLink } from 'views/HomePage/LocationLink'
import { logger } from 'helpers/logger'
import { ProjectTile } from 'views/HomePage/ProjectTile'
import { RequestInfo } from 'views/AppPage/RequestInfo'
import { ProjectMap } from 'views/HomePage/ProjectMap'
import { organizationMapStyles } from 'app/transformers'
import { pageDescription, pageIcons } from 'app/constants'
import gfm from 'remark-gfm'
import { getAppUrl } from 'helpers/config'
import { exportMediaFile } from 'actions/adminActions'
import { ContentGallery } from 'views/AppPage/ContentGallery'
import { HubspotForm } from 'views/AppPage/HubspotForm'
import { packageFormData } from 'helpers/requestHandler'
import { setQueryHelper } from 'helpers/query'
import { getLegacyMediaId } from 'helpers/media'
import { projectSubtitle } from 'helpers/project'

interface EditButtonProps {
    id: string,
    staticLink: string,
    content: CustomContentData,
    onEdit: () => void,
    onOrder: () => void,
    onDelete: () => void,
}

export function EditButtons(props: EditButtonProps) {
    const { id, staticLink, onEdit, onOrder, onDelete } = props
    const isStatic = id?.length != 36
    const editContent = useAppSelector((state) => state.admin.editContent)
    const editing = editContent != null && editContent?.id == id

    const [element, setElement] = useState(null)
    const icon = isStatic ? 'fas fa-plus' : 'fas fa-pen'
    const style = {
        width: element?.clientWidth,
        height: '100%',
        // top: -40,
        // left: 0,
        // left: element ? element.getBoundingClientRect().width - 60 : 10,
        // right: 10,
    }
    if (element) {
        const styleB = getComputedStyle(element);
        const widthWithPadding = element.clientWidth;
        const paddingLeft = parseFloat(styleB.paddingLeft);
        const paddingRight = parseFloat(styleB.paddingRight);
        style.width = widthWithPadding - paddingLeft - paddingRight;
    }

    useEffect(() => {
        // Get element with attribute data-id={id}
        const elem = document.querySelector(`.dynamic-content[data-id="${id}"]`)
        if (!elem) {
            return
        }
        setElement(elem)
    }, [])

    function handleEdit() {
        handleContentAction('edit', { id, staticLink })
    }

    function handleOrder(direction) {
        handleContentAction('order', { id, direction })
    }

    function handleDelete() {
        handleContentAction('delete', { id })
    }
    function handleCancel() {
        handleContentAction('cancel', { id })
    }

    function handleCopy() {
        handleContentAction('copy', { id })

    }

    function handleContentAction(action, data) {
        window.dispatchEvent(new CustomEvent(CustomEventType.ContentAction, { detail: { action, data } }))
    }

    /*useEffect(() => {
        const int = setInterval(applyStyle, element ? 5000 : 1000)
        applyStyle()

        return () => {
            clearInterval(int)
        }
    }, [element])*/

    return <div className={`edit-inline-wrapper ${editing ? ' editing' : ''}`} style={style}>
        <div className="row edit-inline animate__animated animate__zoomIn">
            {!editing && <React.Fragment>
                <IconButton tertiary onClick={handleEdit} icon={icon} />
                {!isStatic && <IconButton tertiary onClick={() => handleOrder(-1)} icon="fas fa-arrow-up" />}
                {!isStatic && <IconButton tertiary onClick={() => handleOrder(1)} icon="fas fa-arrow-down" />}
                {!isStatic && <IconButton tertiary onClick={handleCopy} icon="fas fa-clone" />}
                {!isStatic && <IconButton tertiary onClick={handleDelete} icon="fas fa-trash" />}
            </React.Fragment>}
            {editing && <React.Fragment>
                <IconButton tertiary onClick={handleCancel} icon="fas fa-times" />
            </React.Fragment>}
        </div>
    </div>
}