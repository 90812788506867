import React, { RefObject, useEffect, useState } from 'react'
import { TopBar, CompareButton, EmailFavouritesButton, ClearFavouritesButton, BackButton } from 'components'
import { navigateAsync, parseNav } from 'actions/appActions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FloorplanFilter, PageType, RootState, ScreenOrientation, ScreenSize, QueryType } from 'app/types'

interface OptionBarProps {
    className: string,
    style: {
        [key: string]: string | number
    },
    forwardRef: RefObject,
    leftOptions: JSX.Element[],
    rightOptions: JSX.Element[],
    app: AppData,
    onlyFavourites?: boolean,
    splitIndex?: number,
    titleText?: string,
    compare?: boolean,
    fixed: boolean,
}

export function OptionBar(props: OptionBarProps) {
    const {
        className,
        style,
        forwardRef,
        leftOptions,
        rightOptions,
        app,
        titleText,
        onlyFavourites,
        splitIndex,
        compare,
    } = props
    const dispatch = useAppDispatch()
    const links = useAppSelector((state: RootState) => state.app.links)
    const navigation = useAppSelector((state: RootState) => state.app.navigation)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const compareCommunities = useAppSelector((state: RootState) => state.app.compareCommunities)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const checkpoint = useAppSelector((state: RootState) => state.app.checkpoint)
    const fixedQuery = useAppSelector((state: RootState) => state.app.fixedQueries[QueryType.Floorplans])
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const [backButton, setBackButton] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const fixedLinks = splitIndex != null ? parseNav(navigation[splitIndex], organization, { organization, customDomain, standaloneApp }) : links

    function handleProject() {
        dispatch(navigateAsync({ app, pageType: '', options: { splitIndex } }))
    }

    function handlePage(pageType) {
        dispatch(navigateAsync({ app, pageType, options: { splitIndex: splitIndex } }))
    }
    function handleSearch() {
        dispatch(navigateAsync({ pageType: '', options: { splitIndex: splitIndex, path: { baseRoute: PageType.Developments } } }))
    }

    function handleFavourites() {
        dispatch(navigateAsync({ pageType: '', options: { splitIndex: splitIndex, path: { baseRoute: PageType.Favourite } } }))
    }
    function handleFloorplans() {
        dispatch(navigateAsync({ app, pageType: PageType.Floorplan, options: { splitIndex: splitIndex } }))
    }
    function handleSitemap() {
        dispatch(navigateAsync({ app, pageType: PageType.Sitemap, options: { viewLink: fixedLinks.dataLink, splitIndex: splitIndex } }))
    }
    function handleBuilding() {
        dispatch(navigateAsync({ app, pageType: PageType.Building, options: { viewLink: fixedLinks.dataLink, splitIndex: splitIndex } }))
    }
    function handleCheckpoint() {
        dispatch(navigateAsync(checkpoint.navigate))
    }

    useEffect(() => {
        let newHint = null
        let newTarget = null
        if (onlyFavourites) {
            if (!standaloneApp) {
                if (fixedLinks.appLink && fixedLinks.dataLink) {
                    newHint = 'All Favourites'
                    newTarget = handleFavourites
                } else if (splitIndex == null) {
                    if (false && standaloneOrg) {
                        newHint = 'View Project'
                    } else {
                        newHint = 'Browse Communities'
                    }
                    newTarget = handleSearch
                }
            }
        } else if (app && fixedLinks.appLink && fixedLinks.pageLink && fixedLinks.dataLink && (fixedLinks.pageLink == PageType.Floorplan || fixedLinks.pageLink == PageType.Sitemap || fixedLinks.pageLink == PageType.Building)) {
            newHint = 'All Floor Plans'
            if (fixedLinks.pageLink == PageType.Sitemap) {
                newHint = 'All Floor Plans'
                newTarget = handleSitemap
                /*if (!fixedLinks.extraLink) {
                    backHint = null
                } else {
                    backHint = 'All Floorplans'
                    target = handleSitemap
                }*/
            } else if (fixedLinks.pageLink == PageType.Building) {
                newTarget = handleBuilding
            } else {
                newTarget = handleFloorplans
            }
            if (fixedQuery && fixedQuery[FloorplanFilter.Building] && fixedQuery[FloorplanFilter.Building].length > 0) {
                newHint = `All Building ${fixedQuery[FloorplanFilter.Building]} Floor Plans`
            }
        } else if (checkpoint) {
            newHint = checkpoint.hint
            newTarget = handleCheckpoint
            // target = () => handlePage('')
        } else if (app && fixedLinks.appLink && fixedLinks.pageLink && splitIndex == null && compareCommunities) {
            /*if (fixedLinks.pageLink != PageType.Sitemap) {
                backHint = 'Community Info'
                target = handleProject
            }*/
        } else if (app && fixedLinks.appLink && compareCommunities) {
            newHint = 'Communities'
            newTarget = handleSearch
        } else if (splitIndex != null && screen.size > ScreenSize.Desktop) {
            // newHint = ''
        }

        if (screen.size == ScreenSize.Mobile && newHint != null) {
            newHint = ''
        }

        let back = (newHint != null || newTarget) ? <BackButton hint={newHint.length > 0 ? newHint : null} onClick={newTarget} /> : null
        setBackButton(back)
        setInitialized(true)
    }, [onlyFavourites, app, navigation, compareCommunities, splitIndex])

    let finalTitle = null
    let compareButton = compare && (splitIndex == null && (fixedLinks.pageLink == PageType.Floorplan || fixedLinks.pageLink == PageType.Building || fixedLinks.pageLink == PageType.Sitemap || fixedLinks.appLink == null))

    if (!standaloneApp && (
        (!screen.isMobile && splitIndex != null) ||
        (screen.isMobile && screen.orientation == ScreenOrientation.Landscape && splitIndex == null) ||
        (screen.isMobile && screen.orientation == ScreenOrientation.Portrait && splitIndex == null && leftOptions == null && rightOptions == null && compareButton == false))) {
        finalTitle = titleText
    }

    let finalClass = className ? className : ''
    if (finalTitle != null) {
        finalClass = `${finalClass} with-title`
    }

    if (!leftOptions && !rightOptions && !backButton && !finalTitle) {
        return null
    }

    return <TopBar id="optionbar" sticky className={finalClass} style={style} forwardRef={forwardRef}>
        {(leftOptions || backButton) && initialized && <div className="options left">
            {backButton}
            {leftOptions}
        </div>}
        {/*app && (title || (splitIndex != null && title == null)) && <div className="options center noselect">
            <h3>{splitIndex != -1 && app && app.meta && app.meta.name}</h3>
        </div>*/}
        {finalTitle && <div className="options center noselect">
            <h3>{finalTitle}</h3>
        </div>}

        {(rightOptions || splitIndex == null || app != null) && <div className="options right">
            {splitIndex == null && onlyFavourites && fixedLinks.dataLink == null && <EmailFavouritesButton />}
            {splitIndex == null && onlyFavourites && fixedLinks.dataLink == null && <ClearFavouritesButton />}
            {/* {splitIndex == null && fixedLinks.pageLink != PageType.Sitemap && <CompareButton />} */}
            {compareButton && <CompareButton text={onlyFavourites ? (!screen.isMobile ? 'Compare My Favourites' : 'Compare') : null} />}
            {rightOptions}
        </div>}
    </TopBar>
}
