import React, { useState, useEffect } from 'react'
import { Button, CloseButton } from 'components'
import * as fnc from 'helpers/fnc'
import { DatePicker } from 'rc-datepicker'
import 'rc-datepicker/lib/style.css'
import moment from 'moment'

interface DateRangeProps {
    selected?: { [key: string]: string },
    range?: [date],
    onChange?: { [key: string]: string },
    className?: string,
    inline?: boolean,
}
export function DateRange(props: DateRangeProps) {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [open, setOpen] = useState(props.inline != null)

    function handleChange(queryStart, queryEnd) {
        if (props.onChange) {
            console.trace()
            if (!queryStart || !queryEnd) {
                props.onChange({ startDate: null, endDate: null })
                return
            }
            const newStart = moment(queryStart).startOf('day').toDate().toString()
            const newEnd = moment(queryEnd).endOf('day').toDate().toString()
            props.onChange({ startDate: newStart, endDate: newEnd })
        }
    }

    function setDateRange(newStart, newEnd) {
        setStartDate(newStart)
        setEndDate(newEnd)
        handleChange(newStart, newEnd)
    }
    function handleAllTime() {
        if (props.range) {
            setDateRange(props.range.min, props.range.max)
        } else {
            setDateRange(null, null)
        }
        // setOpen(false)
    }

    function handleToday() {
        setDateRange(new Date(), new Date())
    }

    function handleThisWeek() {
        let start = moment().startOf('week').toDate()
        let end = moment().endOf('week').toDate()
        setDateRange(start, end)
    }

    function handleThisMonth() {
        let start = moment().startOf('month').toDate()
        let end = moment().endOf('month').toDate()
        setDateRange(start, end)
    }

    function handleLastMonth() {
        let start = moment().subtract(1, 'months').startOf('month').toDate()
        let end = moment().subtract(1, 'months').endOf('month').toDate()
        setDateRange(start, end)
    }

    function handleStartDate(date) {
        setStartDate(date)
        handleChange(date, endDate)
    }

    function handleEndDate(date) {
        setEndDate(date)
        handleChange(startDate, date)
    }

    function collapse() {
        setOpen(false)
    }

    useEffect(() => {
        let newStart = null
        let newEnd = null
        if (props.selected != null) {
            if (props.selected.startDate != null && props.selected.startDate != undefined) {
                newStart = new Date(props.selected.startDate)
            }
            if (props.selected.endDate != null && props.selected.endDate != undefined) {
                newEnd = new Date(props.selected.endDate)
            }
        } else if (props.range != null) {
            newStart = props.range.min
            newEnd = props.range.max
        } else {
            newStart = (new Date()).addDays(-7)
            newEnd = new Date()
        }
        if (newStart != null || newEnd != null) {
            setStartDate(newStart)
            setEndDate(newEnd)
            if (props.onChange) {
                props.onChange({ startDate: newStart, endDate: newEnd })
            }
        }

        if (!props.inline) {
            window.addEventListener('click', collapse)
            return () => {
                window.removeEventListener('click', collapse)
            }
        }
    }, [])

    useEffect(() => {
        if (startDate != null && endDate != null && props.selected == null) {
            if (props.range != null) {
                setStartDate(props.range.min)
                setEndDate(props.range.max)
            } else {
                setStartDate((new Date()).addDays(-7))
                setEndDate(new Date())
            }
        } else {
            // setStartDate((new Date()).addYears(-1))
            // setEndDate(new Date())
        }
    }, [props.selected])

    // if (startDate == null && endDate == null) {
    // return null
    // }

    return <div className={`date-range${props.inline ? ' inline' : ''}`}>
        {!props.inline && <Button
            className={props.className ? props.className : null}
            onClick={(e: Event) => { e.stopPropagation(); setOpen(!open) }} >
            {(!startDate || !endDate) ? 'Select Date Range' : ''}
            {startDate && endDate ? `${fnc.dateFriendly(startDate, true)} - ${fnc.dateFriendly(endDate)}` : ''}
        </Button>}
        {open && <div className="date-picker-wrapper" onClick={(e: Event) => e.stopPropagation()}>
            {!props.inline && <CloseButton className="alt no-bg" onClick={() => setOpen(false)} />}
            <div className="date-picker">
                <h4>From {fnc.dateFriendly(startDate)}</h4>
                <DatePicker
                    onChange={handleStartDate}
                    value={startDate != null ? startDate : endDate != null ? endDate.addDays(-7) : new Date()}
                    minDate={props.range && props.range.min}
                    maxDate={endDate} />
            </div>
            <div className="date-picker">
                <h4>To {fnc.dateFriendly(endDate)}</h4>
                <DatePicker
                    onChange={handleEndDate}
                    value={endDate != null ? endDate : startDate != null ? startDate.addDays(7) : new Date()}
                    minDate={startDate}
                    maxDate={props.range && props.range.max} />
            </div>
            <div className="date-ranges">
                {/* <h3 style={{ visibility: 'hidden' }}>Presets</h3> */}
                <Button onClick={handleAllTime}>All Time</Button>
                <Button onClick={handleToday}>Today</Button>
                <Button onClick={handleThisWeek}>This Week</Button>
                <Button onClick={handleThisMonth}>This Month</Button>
                <Button onClick={handleLastMonth}>Last Month</Button>
            </div>
        </div>}
    </div>
}