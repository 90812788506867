import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    initializeProject, initializeStandaloneProject, navigateAsync, setHash,
} from 'actions/appActions'
import { getWindowLocation } from 'helpers/config'
import { ErrorMessage, PageType } from 'app/types'
import { Spinner } from 'components'
import { AppPage } from 'views/AppPage/AppPage'
import { ComparePage } from 'views/ComparePage/ComparePage'
import { ProjectList } from './ProjectList'
import { FavouriteList } from './FavouriteList'
import { NoFavourites } from './NoFavourites'
import { linkSync } from 'fs'

interface ProjectPageProps {
    organizationLink,
    onlyFavourites: boolean,
    splitIndex: number,
    appLink: string,
    pageLink: string,
    dataLink: string,
    extraLink: string,
}
export function ProjectPage(props: ProjectPageProps) {
    const { onlyFavourites, splitIndex, organizationLink, appLink, pageLink, dataLink, extraLink } = props
    const dispatch = useAppDispatch()
    const config = useAppSelector((state: RootState) => state.app.config)
    const { apps, favouriteApps } = config
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const error = useAppSelector((state: RootState) => state.app.error)
    const favourites = useAppSelector((state: RootState) => state.user.favourites)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const links = useAppSelector((state: RootState) => state.app.links)
    const projectCurrent = useAppSelector((state: RootState) => state.app.projectCurrent[splitIndex != null ? splitIndex : 0])
    const [filteredApps, setFilteredApps] = useState(null)
    const stateHash = useAppSelector((state: RootState) => state.app.stateHash)
    const [items, setItems] = useState(null)


    useEffect(() => {
        let newItems = []
        if (onlyFavourites) {
            if (favouriteApps && favourites) {
                newItems = favouriteApps.filter((x) => x.meta.id in favourites)
            }
        } else {
            newItems = apps
        }
        setItems(newItems.filter((x) => !x.meta.maintenanceMode))
    }, [apps, favourites, favouriteApps, onlyFavourites])

    useEffect(() => {
        if (appLink == null && pageLink == null && stateHash != null && getWindowLocation().hash == '') {
            setHash(stateHash)
        }
    }, [appLink, pageLink])

    useEffect(() => {
        if (onlyFavourites && (!favouriteApps || (favouriteApps.find((x) => x.meta.link == appLink) == null))) {
            return
        }
        if (appLink != null && appLink != 'compare' && (!projectCurrent || projectCurrent.meta.link != appLink)) {
            const isolatedProject = (organizationLink == null && !customDomain.organization && links.baseRoute == null)
            if ((standaloneApp || isolatedProject) && splitIndex == null) {
                dispatch(initializeStandaloneProject({ appLink, organizationLink: organization ? organization.link : null }))
            } else {
                dispatch(initializeProject({ appLink, organizationLink: organization ? organization.link : null, splitIndex: splitIndex != null ? splitIndex : 0 }))
            }
        }
    }, [appLink, favouriteApps])

    // Render favourites view
    if (onlyFavourites) {
        // No favourites yet
        if (items == null || items.length == 0) {
            return <NoFavourites />
        }
    }

    // Compare
    if (splitIndex == null) {
        if (appLink === PageType.Compare) {
            return <ComparePage apps={items} onlyFavourites={onlyFavourites} />
        } else if (pageLink == PageType.Compare) {
            return <ComparePage app={projectCurrent} onlyFavourites={onlyFavourites} />
        }
    }
    // Project focus, ensure on favourites only can see floorplans
    if (appLink && (!onlyFavourites || (pageLink == PageType.Floorplan && dataLink != null))) {
        // if (appLink && (!onlyFavourites || pageLink == null || (pageLink == PageType.Floorplan && dataLink != null))) {
        if (projectCurrent && projectCurrent.meta.link === appLink) {
            return <AppPage
                app={projectCurrent}
                pageLink={pageLink}
                dataLink={dataLink}
                extraLink={extraLink}
                splitIndex={splitIndex}
                onlyFavourites={onlyFavourites}
                breadCrumbs
            />
        }
        return <Spinner overlay />
    }

    if (onlyFavourites) {
        return <FavouriteList items={items} breadCrumbs {...props} />
    }
    return <ProjectList items={items} breadCrumbs {...props} />
}
