import { getAxiosInstance, handleResponse, handleError, getOptionString, getSegmentOptions } from 'helpers/requestHandler'
import {
    AppMetaData,
    OrganizationData,
    BuilderData,
    AppData,
    Dict,
    GalleryData,
    UrlOption,
    SegmentOptions,
    MediaTagData,
    MapData,
    AdminOperation,
    CustomContentData,
} from 'app/types'
import { authHeader } from 'helpers/authHeader'


export async function getAdmin(options) {
    return getAxiosInstance().get<AppMetaData>(`/admin${getOptionString(options)}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

/**
 * Data
 */
export async function postData(view: AdminView, data: OrganizationData | BuilderData | AppData, options: UrlOption) {
    let url = `/admin/${view}`
    if (options) {
        url += getOptionString(options)
    }
    return getAxiosInstance().post(url, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getData(view: AdminView, options: Dict = null) {
    let url = `/admin/${view.singular()}`
    if (options != null) {
        url += getOptionString(options)
    }
    return getAxiosInstance().get(url, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function putData(view: AdminView, data: OrganizationData | BuilderData | AppData) {
    return getAxiosInstance().post(`/admin/${view}/${data.id}`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function deleteData(view: AdminView, id: string) {
    return getAxiosInstance().delete(`/admin/${view}/${id}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postAppData(app: AppData, view: AdminView, data: OrganizationData | BuilderData | AppData, options: UrlOption) {
    let url = `/admin/app/${app ? app.meta.id : null}/${view}`
    if (options) {
        url += getOptionString(options)
    }
    return getAxiosInstance().post(url, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getAppData(app: AppData, view: AdminView) {
    return getAxiosInstance().get(`/admin/app/${app ? app.meta.id : null}/${view}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function putAppData(app: AppData, view: AdminView, data: OrganizationData | BuilderData | AppData) {
    return getAxiosInstance().post(`/admin/app/${app ? app.meta.id : null}/${view}/${data.id}`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function deleteAppData(app: AppData, view: AdminView, id: string) {
    return getAxiosInstance().delete(`/admin/app/${app ? app.meta.id : null}/${view}/${id}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postMediaFiles(files, onUploadProgress: () => void, options: SegmentOptions) {
    const formData = new FormData()
    files.forEach(file => formData.append('files[]', file))
    return getAxiosInstance().post(`/admin/media-upload${getSegmentOptions(options)}`, formData, { ...authHeader(), 'Content-Type': 'multipart/form-data', onUploadProgress })
        .then(handleResponse)
        .catch(handleError)
}

export async function postMedia(media, options: SegmentOptions) {
    return getAxiosInstance().post(`/admin/media${getSegmentOptions(options)}`, media, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function putMedia(data: MediaData, options: SegmentOptions) {
    return getAxiosInstance().post(`/admin/media/${data.id}${getSegmentOptions(options)}`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}
export async function deleteMedia(media: string[], options: SegmentOptions) {
    return getAxiosInstance().delete(`/admin/media/${media.join(',')}${getSegmentOptions(options)}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getMediaThumbnails(data: MediaData, options: SegmentOptions) {
    return getAxiosInstance().get(`/admin/media/${data.id}/thumbnails${getSegmentOptions(options)}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postMediaThumbnails(data: MediaData, options: SegmentOptions, file) {
    const formData = new FormData()
    formData.append("file", file) // Add the file to FormData
    
    // Add any additional data to FormData if necessary
    formData.append("data", JSON.stringify(data))
    
    return getAxiosInstance()
        .post(`/admin/media/${data.id}/thumbnails${getSegmentOptions(options)}`, formData, {
            headers: {
                ...authHeader().headers,
                "Content-Type": "multipart/form-data", // Ensure multipart form-data header
            },
        })
        .then(handleResponse)
        .catch(handleError)
}


export async function getMediaExport(data: MediaData, options: SegmentOptions) {
    return getAxiosInstance().get(`/admin/media/${data.id}/export${getSegmentOptions(options)}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postMap(map: MapData) {
    return getAxiosInstance().post('/admin/map', map, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getMap(mapLink: string, options) {
    return getAxiosInstance().get(`/admin/map/${mapLink}${getSegmentOptions(options)}`)
        .then(handleResponse)
        .catch(handleError)
}

export async function putMap(map: MapData) {
    return getAxiosInstance().post(`/admin/map/${map.id}`, map, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getJob(jobId: string) {
    return getAxiosInstance().get(`/admin/job/${jobId}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postStartJob(jobId: string) {
    return getAxiosInstance().post(`/admin/job/${jobId}/start`, null, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postMediaTag(data: MediaTagData, options: SegmentOptions) {
    return getAxiosInstance().post(`/admin/mediatag${getSegmentOptions(options)}`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function putMediaTag(data: MediaTagData, options: SegmentOptions) {
    return getAxiosInstance().post(`/admin/mediatag/${data.id}${getSegmentOptions(options)}`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function deleteMediaTag(data: SitemapData, options: SegmentOptions) {
    return getAxiosInstance().delete(`/admin/mediatag/${data.id}${getSegmentOptions(options)}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postGallery(data: GalleryData, options: SegmentOptions) {
    return getAxiosInstance().post(`/admin/gallery${getSegmentOptions(options)}`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function putGallery(data: GalleryData, options: SegmentOptions) {
    return getAxiosInstance().post(`/admin/gallery/${data.id}${getSegmentOptions(options)}`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function deleteGallery(data: GalleryData, options: SegmentOptions) {
    return getAxiosInstance().delete(`/admin/gallery/${data.id}${getSegmentOptions(options)}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

// Logs
export async function getLogList(logType: LogType) {
    return getAxiosInstance().get(`/admin/log/${logType}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getLog(logType: LogType, filename) {
    return getAxiosInstance().get(`/admin/log/${logType}/${filename}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postRunOperation(operation: AdminOperation, data) {
    return getAxiosInstance().get(`/admin/operation/${operation}`, authHeader())
    // return getAxiosInstance().post(`/admin/operation/${operation}`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

// Custom content
export async function postContent(app: AppData, organization: OrganizationData, data: CustomContentData) {
    let url = ''
    if(app) {
        url = `/admin/app/${app.meta.id}/content`
    } else if (organization) {
        url = `/admin/organization/${organization.meta.id}/content`
    }
    if(data.id != null) {
        url += `/${data.id}`
    }
    if(data.type != null) {
        data.dynamicContentTypeId = data.type
    }
    return getAxiosInstance().post(url, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function deleteContent(app: AppData, organization: OrganizationData, data: CustomContentData) {
    let url = ''
    if(app) {
        url = `/admin/app/${app.meta.id}/content/${data.id}`
    } else if (organization) {
        url = `/admin/organization/${organization.meta.id}/content/${data.id}`
    }
    return getAxiosInstance().delete(url, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

// Locatio
export async function postLocation(app: AppData, organization: OrganizationData, data: Location) {
    let url = ''
    if(app) {
        url = `/admin/app/${app.meta.id}/location`
    } else if (organization) {
        url = `/admin/organization/${organization.meta.id}/location`
    }
    if(data.id != null) {
        url += `/${data.id}`
    }
    return getAxiosInstance().post(url, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function deleteLocation(app: AppData, organization: OrganizationData, data: Location) {
    let url = ''
    if(app) {
        url = `/admin/app/${app.meta.id}/location/${data.id}`
    } else if (organization) {
        url = `/admin/organization/${organization.meta.id}/location/${data.id}`
    }
    return getAxiosInstance().delete(url, authHeader())
        .then(handleResponse)
        .catch(handleError)
}