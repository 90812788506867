import React, { useState, useEffect, useRef } from 'react'
import { logger } from 'helpers/logger'
import { Spinner, CollapsibleRow, Button } from 'components'
import { ErrorMessage, CustomEventType, Dict, MarkerType, RootState, PromptType } from 'app/types'
import { loadMapScripts } from 'helpers/scripts'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import * as fnc from 'helpers/fnc'
import { exportMediaFile, retrieveMap, upsertMap } from 'actions/adminActions'
import { parseKML } from 'helpers/kml'
import ReactMarkdown from 'react-markdown'
import { resolvePending, showPrompt, updatePrompt } from 'actions/appActions'

const CLICK_THRESH = 10
const LABEL_SIZE = 14

let icons = null
function getIcon(key: string, options = {}) {
    if (icons == null) {
        // Custom marker https://stackoverflow.com/questions/2472957/how-can-i-change-the-color-of-a-google-maps-marker
        // Pick your pin (hole or no hole)
        var pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
        var labelOriginHole = new window.google.maps.Point(12, 15)
        var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z"
        var labelOriginFilled = new window.google.maps.Point(12, 9)
        var pinSVGHeart = "M12,2C8.134,2,5,5.134,5,9c0,5.25,7,13,7,13s7-7.75,7-13 C19,5.134,15.866,2,12,2z M12.12,12.886L12,12.985l-0.12-0.099c-2.561-2.084-3.255-2.819-3.255-4.011 c0-0.992,0.794-1.786,1.786-1.786c0.813,0,1.27,0.457,1.588,0.814c0.318-0.357,0.774-0.814,1.588-0.814 c0.993,0,1.786,0.794,1.786,1.786C15.375,10.066,14.681,10.801,12.12,12.886z"
        var labelOriginHeart = new window.google.maps.Point(12, 15)
        var star = "m17.56 21a1 1 0 0 1 -.46-.11l-5.1-2.67-5.1 2.67a1 1 0 0 1 -1.45-1.06l1-5.63-4.12-4a1 1 0 0 1 -.25-1 1 1 0 0 1 .81-.68l5.7-.83 2.51-5.13a1 1 0 0 1 1.8 0l2.54 5.12 5.7.83a1 1 0 0 1 .81.68 1 1 0 0 1 -.25 1l-4.12 4 1 5.63a1 1 0 0 1 -.4 1 1 1 0 0 1 -.62.18z"
        var pinStar = "m12 2c-3.9 0-7 3.1-7 7 0 5.2 7 13 7 13s7-7.8 7-13c0-3.9-3.1-7-7-7m2.5 11-2.5-1.5-2.5 1.5.7-2.8-2.2-1.9 2.9-.2 1.1-2.7 1.1 2.6 2.9.3-2.2 1.9z"
        var labelOriginStar = new window.google.maps.Point(12, 15)

        const secondaryMid = fnc.getRootProperty('--secondary-mid')
        const tertiaryMid = fnc.getRootProperty('--tertiary-mid')
        const circleColor = fnc.getRootProperty('--map-circle', document.getElementById('app'))
        const circleColorOutline = fnc.getRootProperty('--map-circle-outline', document.getElementById('app'))


        // https://developers.window.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
        icons = {
            basic: {
                path: pinSVGHole,
                anchor: new window.google.maps.Point(12, 17),
                fillOpacity: 1,
                fillColor: '#ea4335',
                strokeWeight: 1.5,
                strokeColor: 'white',
                scale: 2,
                labelOrigin: labelOriginHole,
            },
            hover: {
                path: pinSVGHole,
                anchor: new window.google.maps.Point(12, 17),
                fillOpacity: 1,
                fillColor: secondaryMid,
                strokeWeight: 1.5,
                strokeColor: 'white',
                scale: 2,
                labelOrigin: labelOriginHole,
            },
            favourite: {
                path: pinSVGHeart,
                anchor: new window.google.maps.Point(12, 17),
                fillOpacity: 1,
                fillColor: secondaryMid,
                strokeWeight: 1.5,
                strokeColor: 'white',
                scale: 2,
                labelOrigin: labelOriginHole,
            },
            circle: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 15,
                fillColor: circleColor,
                fillOpacity: 1,
                strokeWeight: 0,
            },
            circleFocus: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 15,
                fillColor: circleColor,
                fillOpacity: 1,
                strokeColor: circleColorOutline,
                strokeWeight: 2,
            },
            star: {
                path: star,
                anchor: new window.google.maps.Point(12, 17),
                fillOpacity: 1,
                fillColor: secondaryMid,
                strokeWeight: 1.5,
                strokeColor: 'white',
                scale: 1.5,
                labelOrigin: labelOriginStar,
            },
        }
    }
    if (options && options.size != null) {
        const { size, ...otherOptions } = options
        return { ...icons[key], scale: icons[key].scale * size, ...otherOptions }
    } else if (options) {
        return { ...icons[key], options }
    } else {
        return icons[key]
    }
}

function offsetCenter(map, latlng, offsetx, offsety) {
    // https://stackoverflow.com/questions/10656743/how-to-offset-the-center-point-in-google-maps-api-v3
    // latlng is the apparent centre-point
    // offsetx is the distance you want that point to move to the right, in pixels
    // offsety is the distance you want that point to move upwards, in pixels
    // offset can be negative
    // offsetx and offsety are both optional
    var scale = Math.pow(2, map.getZoom())

    var worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng)
    var pixelOffset = new window.google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0)

    var worldCoordinateNewCenter = new window.google.maps.Point(
        worldCoordinateCenter.x - pixelOffset.x,
        worldCoordinateCenter.y + pixelOffset.y
    )

    var newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter)
    map.setCenter(newCenter)
}

function panWithOffset(map, latlng, zoom = null) {
    // offsetCenter(map, latlng, 0, 100)
    var scale = Math.pow(2, map.getZoom())
    const offset = 0//40 / scale
    const finalLatLng = { lat: latlng.lat, lng: latlng.lng + offset }
    latlng.lat += offset
    map.panTo(latlng)
    if (zoom != null) {
        map.setZoom(zoom)
    }
}

type PopupMarkerProps = {
    map: window.google.maps.Map,
    id: string,
    type: MarkerType,
    position: { lat: number, lng: number },
    value: Dict,
    element: JSX.Element,
    focusCallback: () => void,
    selectCallback: () => void,
    autoShow?: boolean,
    focus: boolean,
    defaultZoom: number,
    favourite?: boolean,
    isSplit?: boolean,
    label: string,
    hideMobile: boolean,
    center: boolean
}

function PopupMarker(props: PopupMarkerProps) {
    const {
        map,
        id,
        type,
        value,
        position,
        element,
        focusCallback,
        selectCallback,
        focus,
        defaultZoom,
        favourite,
        isSplit,
        label,
        hideMobile,
        center,
        ...iconOptions
    } = props

    const [marker, setMarker] = useState(null)
    const [popup, setPopup] = useState(null)
    const [isFocus, setIsFocus] = useState(false)
    const [basicIcon, setBasicIcon] = useState(false)
    const [hoverIcon, setHoverIcon] = useState(false)
    const screen = useAppSelector((state: RootState) => state.app.screen)

    const popupElement = useRef()
    const parentElement = useRef()
    const closeElement = useRef()
    const popupId = `popup-${id}`
    const markerId = `marker-${id}`

    const prevMouse = useRef()
    const currentIcon = useRef()

    /**
     * A customized popup on the map.
     */
    class Popup extends window.google.maps.OverlayView {
        position
        contentDiv
        containerDiv

        constructor(position, content) {
            super()
            this.position = position
            content.classList.add("popup-bubble")

            // This zero-height div is positioned at the bottom of the bubble.
            const bubbleAnchor = document.createElement("div")
            bubbleAnchor.classList.add("popup-bubble-anchor")
            // const newContent = content.cloneNode(true)
            bubbleAnchor.appendChild(content)
            // This zero-height div is positioned at the bottom of the tip.
            this.containerDiv = document.querySelector(`.popup-container[data-id="${id}"]`)
            if (!this.containerDiv) {
                this.containerDiv = document.createElement("div")
                this.containerDiv.classList.add("popup-container")
                this.containerDiv.setAttribute('data-id', id)
                this.containerDiv.classList.add("fadeIn")
                this.containerDiv.appendChild(bubbleAnchor)
            }
            // Optionally stop clicks, etc., from bubbling up to the map.
            Popup.preventMapHitsAndGesturesFrom(this.containerDiv)
        }

        /** Called when the popup is added to the map. */
        onAdd() {
            this.getPanes().floatPane.appendChild(this.containerDiv)
        }

        /** Called when the popup is removed from the map. */
        onRemove() {
            if (this.containerDiv.parentElement) {
                this.containerDiv.parentElement.removeChild(this.containerDiv)
                // this.containerDiv.parentElement.remove()
            }
        }
        /** Called each frame when the popup needs to draw itself. */
        draw() {
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position)

            // Hide the popup when it is far out of view.
            const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none"

            if (display === "block") {
                this.containerDiv.style.left = divPosition.x + "px"
                this.containerDiv.style.top = divPosition.y - 20 + "px"
            }

            if (this.containerDiv.style.display !== display) {
                this.containerDiv.style.display = display
            }
        }
    }

    useEffect(() => {
        let newMarker = null

        let newIcon = ''
        let newHoverIcon = ''

        switch (type) {
            case MarkerType.Circle:
                newIcon = 'circle'
                newHoverIcon = 'circleFocus'
                newMarker = new window.google.maps.Marker({
                    position,
                    map,
                    // animation: window.google.maps.Animation.DROP,
                    id: markerId,
                    icon: getIcon(newIcon, iconOptions),
                    label: {
                        color: 'white',
                        text: label?.toString(),
                    }
                })
                break
            case MarkerType.Neighbourhood:
                newIcon = 'star'
                newHoverIcon = 'star'
                newMarker = new window.google.maps.Marker({
                    position,
                    map,
                    // animation: window.google.maps.Animation.DROP,
                    id: markerId,
                    icon: getIcon(newIcon, iconOptions),
                    // label: {
                    // color: 'white',
                    // text: label?.toString(),
                    // }
                })
                break
            default:
                newIcon = favourite ? 'favourite' : 'basic'
                newHoverIcon = favourite ? 'favourite' : 'hover'

                newMarker = new window.google.maps.Marker({
                    position,
                    map,
                    animation: window.google.maps.Animation.DROP,
                    id: markerId,
                    icon: getIcon(newIcon, iconOptions)
                    // title,
                })
                break
        }
        setBasicIcon(newIcon)
        setHoverIcon(newHoverIcon)
        currentIcon.current = newIcon

        let newElement = null
        let newPopup = null
        if (element) {
            // Create new element
            let template = document.createElement('template')
            template.innerHTML = element.trim()
            newElement = template.content.firstChild
            popupElement.current = newElement

            // Create close button
            template = document.createElement('template')
            template.innerHTML = '<div class="icon-button noselect close-button top-right alt no-bg"><i class="fas fa-times" aria-hidden="true"/></div>'
            const closeButton = template.content.firstChild
            newElement.appendChild(closeButton)
            closeElement.current = closeButton

            newPopup = new Popup(new window.google.maps.LatLng(position.lat, position.lng), newElement)
            newPopup.setMap(map)

            parentElement.current = newElement.parentElement.parentElement
            hidePopup()
        } else {
            parentElement.current = 'none'
        }

        setMarker(newMarker)

        return () => {
            if (newPopup) {
                newPopup.setMap(null)
                newPopup.onRemove()
            }
            if (newMarker) {
                newMarker.setMap(null)
            }
            hidePopup()
            if (popupElement.current) {
                popupElement.current.remove()
            }
            if (parentElement.current && typeof parentElement.current.remove == 'function') {
                parentElement.current.remove()
            }
            if (newElement) {
                newElement.remove()
            }
        }
    }, [])

    useEffect(() => {
        if (!parentElement.current || !marker) {
            return
        }

        if (!parentElement.current || parentElement.current == 'none') {
            const e1 = marker.addListener('click', handleFocus)
            return () => {
                window.google.maps.event.removeListener(e1)
            }
        } else {
            parentElement.current.addEventListener('mousedown', handleMouseDown)
            parentElement.current.addEventListener('mouseup', handleMouseUp)
            popupElement.current.addEventListener('click', handleSelect)
            closeElement.current.addEventListener('mousedown', clearFocus)

            const events = []
            events.push(marker.addListener('mousedown', handleMouseDown))
            events.push(marker.addListener('mouseup', handleMouseUp))
            if (!screen.isMobile) {
                events.push(marker.addListener('mouseover', () => {
                    if (favourite) {
                        return
                    }
                    handleIcon('hover')
                }))
                events.push(marker.addListener('mouseout', () => {
                    if (favourite) {
                        return
                    }
                    if (!focus) {
                        handleIcon('basic')
                    }
                }))
            }
            if (!favourite) {
                if (focus) {
                    handleIcon('hover')
                } else {
                    handleIcon('basic')
                }
            }

            window.addEventListener(CustomEventType.CloseAllMenus, hidePopup)
            window.addEventListener(CustomEventType.FocusMarker, handleFocus)
            window.addEventListener(CustomEventType.ShowMapPopup, showPopup)
            window.addEventListener(CustomEventType.HideMapPopup, hidePopup)

            return () => {
                parentElement.current.removeEventListener('mousedown', handleMouseDown)
                parentElement.current.removeEventListener('mouseup', handleMouseUp)
                closeElement.current.removeEventListener('mousedown', clearFocus)
                popupElement.current.removeEventListener('onclick', handleSelect)

                events.forEach((x) => {
                    window.google.maps.event.removeListener(x)
                })

                window.removeEventListener(CustomEventType.CloseAllMenus, hidePopup)
                window.removeEventListener(CustomEventType.FocusMarker, handleFocus)
                window.removeEventListener(CustomEventType.ShowMapPopup, showPopup)
                window.removeEventListener(CustomEventType.HideMapPopup, hidePopup)
            }
        }
    }, [marker != null && parentElement.current != null, focus])

    useEffect(() => {
        if (!parentElement.current || !marker) {
            return
        }
        if (!focus && parentElement.current.style && parentElement.current.style.visibility != 'hidden') {
            hidePopup()
        } else if (focus && parentElement.current.style && parentElement.current.style.visibility != 'inherit') {
            showPopup()
        }

    }, [focus, marker])

    function getPosition(e) {
        if (!e.domEvent) {
            return
        }

        if (e.domEvent.changedTouches && e.domEvent.changedTouches.length > 0) {
            return { x: e.domEvent.changedTouches[0].pageX, y: e.domEvent.changedTouches[0].pageY }
        } else {
            return { x: e.domEvent.pageX, y: e.domEvent.pageY }
        }
    }

    function handleMouseDown(e) {
        prevMouse.current = getPosition(e)
    }

    function handleMouseUp(e) {
        if (prevMouse.current) {
            const newMouse = getPosition(e)
            const dif = Math.sqrt(Math.pow(prevMouse.current.x - newMouse.x, 2) + Math.pow(prevMouse.current.y - newMouse.y, 2))
            if (dif < CLICK_THRESH) {
                clearFocus(e.domEvent)
                setTimeout(() => {
                    showPopup(e.domEvent)
                    handleFocus(e.domEvent)
                }, 0)
            }
        }
    }

    function handleIcon(icon) {
        if (!marker) {
            return
        }
        let size = 1
        let zIndex = parseInt(label)
        if (type == MarkerType.Circle) {
            if (icon == 'hover') {
                currentIcon.current = hoverIcon
                size = 1.3
                zIndex = 1000
            } else {
                currentIcon.current = basicIcon
            }
        } else {
            if (icon == 'hover' || center) {
                size = 1.3
                zIndex = 1000
            }
            currentIcon.current = basicIcon
        }
        marker.setIcon(getIcon(currentIcon.current, { size, ...iconOptions }))
        marker.setZIndex(zIndex)
        if (label) {
            marker.setLabel({
                color: 'white',
                text: label?.toString(),
                fontSize: `${LABEL_SIZE * size}px`,
            })
        }
    }

    function clearFocus(e: Event) {
        if (focusCallback) {
            focusCallback(null)
        }
        e.stopPropagation()
        hidePopup()
    }

    function handleSelect(e: Event) {
        if (selectCallback) {
            selectCallback(value)
        }
    }
    function handleFocus(e: Event) {
        // Detail implies from window event
        if (e.detail && e.detail.id && e.detail.type) {
        } else {
            if (focusCallback && focusCallback(value)) {
                panWithOffset(map, position)
            }
            setTimeout(() => {
                window.dispatchEvent(new CustomEvent(CustomEventType.CloseAllMenus, { detail: id }))
            }, 10000)
        }
    }

    function hidePopup(e) {
        if (focus) {
            return
        }
        if (e && e.detail && e.detail.id && e.detail.id != id) {
            return
        }
        parentElement.current.style.zIndex = 100

        if (e && e.detail && e.detail == id) {
            return
        }
        if (focus) {
            return
        }

        parentElement.current.style.visibility = 'hidden'
        handleIcon('basic')
    }

    function showPopup(e, isFocus = false) {
        // if (isSplit || (hideMobile && screen.isMobile)) {
            // return
        // }
        if (e && e.detail && e.detail.id && e.detail.id != id) {
            return
        }
        parentElement.current.style.zIndex = (focus || isFocus) ? 80 : 100
        if (e && e.stopPropagation) {
            e.stopPropagation()
        }
        parentElement.current.style.visibility = 'inherit'
        handleIcon('hover')
        // setIsFocus(true)
    }

    return null
}

interface MapProps {
    id: string,
    app: AppData,
    organization: OrganizationData,
    title: string,
    body: string,
    markers: PopupMarkerProps[],
    focusIds: string[],
    defaultZoom: number,
    scrollable?: boolean,
    remember?: boolean,
    isSplit?: boolean,
    controls?: JSX.Element[]
    moveToFocus: boolean,
    options: Dict,
    styles: Dict,
    fitPadding: number
    sidebarId: string,
    invert: boolean,
    generate: boolean,
    sidebar: boolean
    kml: MediaData,
    elements: Dict,
    elementMap: () => void,
    onFocus,
    hideMobile: boolean,
    onReset: () => void,
    defaultMarker: string,
}

export function Map(props: MapProps) {
    const { id, app, organization, focusIds, defaultZoom, remember, moveToFocus, controls, options, styles, fitPadding, kml, invert, generate, sidebar, elementMap, onFocus, hideMobile, defaultMarker, onReset } = {
        id: 'all',
        defaultZoom: 8,
        remember: false,
        focusIds: [],
        markers: [],
        moveToFocus: false,
        fitPadding: 30,
        styles: [
            {
                'featureType': 'administrative',
                'elementType': 'geometry',
                'stylers': [{
                    'visibility': 'off'
                }]
            },
            {
                'featureType': 'poi',
                'stylers': [{
                    'visibility': 'off'
                }]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [{
                    'visibility': 'off'
                }]
            },
            {
                'featureType': 'transit',
                'stylers': [{
                    'visibility': 'off'
                }]
            }
        ],
        ...props
    }
    const [error, setError] = useState(null)
    const [map, setMap] = useState(null)
    const [title, setTitle] = useState(props.title)
    const [body, setBody] = useState(props.body)
    const [markers, setMarkers] = useState(null)
    const [elements, setElements] = useState(null)
    const [places, setPlaces] = useState(null)
    const [icons, setIcons] = useState(null)
    const [focusElement, setFocusElement] = useState(null)
    const [focusElementGroup, setFocusElementGroup] = useState(null)

    const [focusInitialized, setFocusInitialized] = useState(false)
    const [centerElement, setCenterElement] = useState(null)
    const [generateElement, setGenerateElement] = useState(null)
    const [positionCache, setPositionCache] = useState(null)
    const [mapRef, setMapRef] = useState(null)
    const screen = useAppSelector((state: Rootstate) => state.app.screen)
    const initialized = useRef()
    const dispatch = useAppDispatch()

    useEffect(() => {
        // Load places
        const options = {
            app: app,
            organization: !app ? organization : null,
        }
        dispatch(retrieveMap({ link: id, options }))
            .then((x) => {
                if (x.payload?.message?.places) {
                    handlePlaces(x.payload.message.places)
                }
            })
    }, [])

    useEffect(() => {
        // Gather elements
        const gatherElements = async () => {
            let newElements = props.elements
            // If using config kml, generate / load data
            if (kml) {
                // Parse kml
                const ret = await dispatch(exportMediaFile({ media: kml, options: { app } }))
                if (ret) {
                    const parsed = parseKML(ret.payload.message.document)
                    newElements = parsed.elements
                    if (!props.title) {
                        setTitle(parsed.title)
                    }
                    if (!props.body) {
                        setBody(parsed.body)
                    }
                    setIcons(parsed.icons)
                }
            } else if (newElements) {
                let index = 0
                newElements?.forEach((x) => {
                    x.elements.forEach((y) => {
                        index += 1
                        if (y.id == null) {
                            y.id = index
                        }
                    })
                })
            }

            // Generate markers from elements
            if (newElements) {
                setElements(newElements)
                // Otherwise if provided in props, use those
            } else {
                setElements([])
            }
        }
        gatherElements()
    }, [])

    useEffect(() => {
        if (!elements) {
            return
        }

        // With elements and places initialized, generate the markers

        let index = 0
        if (props.markers) {
            setMarkers(props.markers)
        } else {
            const newMarkers = []
            elements.forEach((x, ix) => {
                const skip = focusElementGroup != null && ix != focusElementGroup
                x.elements.forEach((y) => {
                    const location = y.locations && y.locations.length > 0 ? y.locations[0] : null
                    index += 1
                    if (skip && y.id != defaultMarker) {
                        return
                    }
                    const [lat, lng] = location?.latLng ? location.latLng.split(',').map((x) => parseFloat(x.trim())) : [0, 0]
                    const style = icons ? icons[y.style] : {}
                    let marker = {
                        id: y.id,
                        type: y.id == defaultMarker ? MarkerType.Neighbourhood : MarkerType.Circle,
                        position: { lat, lng },
                        value: y.id,
                        label: y.id ? y.id : index,
                        name: y.title,
                        style: style,
                        focusCallback: (x) => handleElementFocus(null, x),
                        element: elementMap && elementMap(y, places ? places[y.id] : null),
                    }
                    if (y.id == defaultMarker) {
                        marker.label = ''
                        marker.fillColor = fnc.getRootProperty('--tertiary-mid')
                    }
                    newMarkers.push(marker)
                })
            })
            setMarkers(newMarkers)
        }
    }, [elements, places, focusElementGroup])


    useEffect(() => {
        if (!mapRef) {
            return
        }
        let cache = localStorage.getItem(`map-${id}`)
        if (cache != null) {
            cache = JSON.parse(cache)
        }
        if (cache == null) {
            cache = {}
        }
        setPositionCache(cache)

        loadMapScripts()
            .then((x) => {
                if (!initialized.current) {
                    initializeMap(cache)
                    initialized.current = true
                    logger.info('Maps initialized')
                }
            })
            .catch((e) => {
                logger.error(e)
                setError(ErrorMessage.MapError)

            })
        /*const tryInit = (tries) => {
            loadMapScripts()
                .then((x) => {
                    try {
                        initializeMap(cache)
                    } catch (e: Exception) {
                        logger.info('Failed to load map, trying again in 100ms', e)
                        if (tries > 0) {
                            tryInit(tries - 1)
                        } else {
                            logger.error('Failed to load map')
                            setError(ErrorMessage.MapError)
                        }
                    }
                })&*/
        // }
        // tryInit(10)
        // .then(tryInit)
        /*try {
            initializeMap()
        } catch(e: Exception) {
            // Try one more time
            logger.error('Failed to load map, trying again', e)
            setTimeout(() => {
                try {
                    initializeMap()
                } catch(e: Exception)

            }, 100)
            setError(ErrorMessage.MapError)
        }
    })*/
        /*new Promise((resolve, reject) => {
            const existingScript = document.getElementById('googleMaps')
            if (!existingScript) {
                const script = document.createElement('script')
                // script.src = 'https://maps.googleapis.com/maps/api/js'
                script.src = `https://maps.googleapis.com/maps/api/js?key=${getMapsApiKey()}&libraries=places,geometry`
                script.id = 'googleMaps'
                document.body.appendChild(script)
                script.onload = resolve
                script.onerror = reject
            } else {
                resolve()
            }
        }).then(addMapClasses).then(initializeMap)
        .catch((x) => {
            logger.error(x)
            setError(ErrorMessage.MapError)
        })*/

    }, [mapRef])

    useEffect(() => {
        if (centerElement) {
            centerElement.addEventListener('click', handleFocusCurrent)
            return () => {
                centerElement.removeEventListener('click', handleFocusCurrent)
            }
        }

    }, [centerElement, focusIds ? focusIds.join('') : '', map, markers])

    useEffect(() => {
        if (generateElement) {
            generateElement.addEventListener('click', handleGeneratePlaces)
            return () => {
                generateElement.removeEventListener('click', handleGeneratePlaces)
            }
        }

    }, [generateElement, map, markers])

    useEffect(() => {
        if (!map || !remember) {
            return
        }
        // Post map initialization
        const e1 = map.addListener('center_changed', handleRemember)
        const e2 = map.addListener('zoom_changed', handleRemember)
        return () => {
            window.google.maps.event.removeListener(e1)
            window.google.maps.event.removeListener(e2)
        }


    }, [map, markers])

    useEffect(() => {
        if (focusElement) {
            const id = `map-sidebar-entry-${focusElement}`
            const elem = document.getElementById(id)
            if (elem) {
                elem.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
            }
        }
    }, [focusElement])

    function handlePlaces(places) {
        const newPlaces = places.reduce((acc, x) => ({ ...acc, [x.link]: x }), {})
        setPlaces(newPlaces)
    }

    function handleRemember(e) {
        const newPos = { position: map.center, zoom: map.zoom, markerKey: markers.map((x) => x.id).join() }
        let newCache = { ...positionCache, [id]: newPos }
        setPositionCache(newCache)
        localStorage.setItem(`map-${id}`, JSON.stringify(newCache))
    }

    function handleFocusCurrent() {
        if (focusIds.length == 1) {
            let m = markers.find((x) => x.id == focusIds[0])
            if (m) {
                map.panTo(m.position)
                // panWithOffset(map, m.position)
            }
        } else {
            fitToMarkers()
        }
    }


    const handleElementGroup = (e) => {
        setFocusElementGroup(e)
        setFocusElement(null)
    }

    const handleElementFocus = (e, x) => {
        if (e) {
            e.stopPropagation()
        }
        if (onFocus) {
            onFocus(x)
        }
        setFocusElement(x)
        if (x) {
            window.dispatchEvent(new CustomEvent(CustomEventType.ShowMapPopup, { detail: { id: x } }))
        }
    }

    const handleElementMouseOver = (e, x) => {
        if (e) {
            e.stopPropagation()
        }
        window.dispatchEvent(new CustomEvent(CustomEventType.ShowMapPopup, { detail: { id: x } }))
    }

    const handleElementMouseLeave = (e, x) => {
        if (e) {
            e.stopPropagation()
        }
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent(CustomEventType.HideMapPopup, { detail: { id: x } }))
        }, 10)
    }

    function handleGeneratePlaces(force = false) {
        if (!markers || (places && !force)) {
            return
        }

        let subtitle = title ? `for ${title}` : ''
        dispatch(showPrompt({ type: PromptType.Pending, title: 'Gathering places', subtitle: subtitle, successMessage: 'Done!' }))

        const total = markers.length * 2
        let progress = 0
        const requestDelay = 1000

        const makeProgress = (message) => {
            progress += 1
            logger.info(`Gathering places ${progress}/${total}: ${message}`)
            dispatch(updatePrompt({ subtitle: `${subtitle} (${Math.floor(100 * progress / total)}%)` }))
        }

        Promise.all(markers.map((x, ix) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const marker = x
                    const request = {
                        // query: `${x.position.lat},${x.position.lng} ${x.name}`,
                        query: `${x.name}`,
                        fields: ['place_id', 'name'],
                        locationBias: { lat: x.position.lat, lng: x.position.lng },
                    }

                    const service = new google.maps.places.PlacesService(map)
                    service.findPlaceFromQuery(request, function (results, status) {
                        makeProgress("Get place id")
                        if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                            resolve({ marker, place_id: results[0].place_id })
                        } else {
                            logger.error("Failed to get place", request, status)
                            resolve({ marker, place_id: null })
                            // reject(status)
                        }
                    })
                }, ix * requestDelay)
            })
        }))
            .then((ret) => {
                const service = new google.maps.places.PlacesService(map)
                return Promise.all(ret.filter((x) => x.place_id != null).map(({ marker, place_id }, ix) => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            // Next get actual place details
                            const request = {
                                placeId: place_id,
                                fields: [
                                    'name',
                                    // 'business_status',
                                    'formatted_address',
                                    // 'geometry',
                                    'icon',
                                    'icon_mask_base_uri',
                                    'icon_background_color',
                                    'photos',
                                    // 'plus_code',
                                    // 'types',
                                    'url',
                                    'website'
                                ],
                            }

                            service.getDetails(request, function (results, status) {
                                makeProgress("Get place details")
                                if (status === google.maps.places.PlacesServiceStatus.OK) {
                                    // Get all photos urls
                                    const place = {}
                                    Object.keys(results).forEach((x) => {
                                        place[x.toCamelCase()] = results[x] // Convert to client side naming convention
                                    })
                                    let newPhotos = place.photos ? place.photos.map((x) => {
                                        return x.getUrl({ maxWidth: 400, maxHeight: 400 })
                                    }) : []
                                    place.photos = newPhotos
                                    resolve({ marker, result: place })
                                } else {
                                    reject(status)
                                }
                            })
                        }, ix * requestDelay)
                    })
                }))
            })
            .then(async (results) => {
                const newPlaces = {}
                results.forEach((x) => {
                    newPlaces[x.marker.id] = x.result
                })
                const map = {
                    id: null,
                    link: id,
                    appId: app ? app.meta.id : null,
                    organizationId: (organization && !app) ? organization.id : null,
                    mediaId: kml ? kml.id : null,
                    places: newPlaces
                }
                // Save results to db
                const ret = await dispatch(upsertMap(map))
                if (ret.payload.success) {
                    handlePlaces(ret.payload.message.places)
                    logger.info("New Places", newPlaces)
                }
                return dispatch(resolvePending(true))
            }).catch((e) => {
                logger.error("Failed to get places", e)
                setPlaces({})
                dispatch(resolvePending(`Failed to get places ${e}`))
            })
    }

    function initializeMap(cache) {
        if (!window.google) {
            throw new Error('Missing google scripts')
        }
        // Default toronto
        let center = { lat: 43.703703319815276, lng: -79.4007491751964 }
        let zoom = defaultZoom

        if (remember && cache && id in cache) {
            center = cache[id].position
            zoom = cache[id].zoom
        }
        /*if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                    pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }
                },
                () => {
                    logger.error('Failed to get location')
                },
            )
        } else {
            logger.error('Browser doesn\'t support location')
        }*/


        const element = document.getElementById(`googlemap-${id}`)
        if (!element) {
            // logger.error('Missing map div')
            setError(ErrorMessage.MapError)
            return
        }

        const newMap = new window.google.maps.Map(element, {
            gestureHandling: 'cooperative',
            scrollwheel: props.scrollable != null ? props.scrollable : true,
            center: center,
            zoom: zoom,
            mapTypeControl: !screen.isMobile,
            streetViewControl: !screen.isMobile,
            styles: styles,
            ...options,
        })
        newMap.setZoom(zoom)
        // Redundant but needed to fix zoom
        /*for (let i = 0; i < 3; i++) {
            setTimeout(() => {
                newMap.setZoom(zoom)
            }, i * 300)
        }*/

        let newControls = []
        // Add custom constrols
        let controlDiv = document.createElement('div')

        let template = document.createElement('template')
        template.innerHTML = '<div class="button bottomleft noselect with-icon alt"><div class="icon noselect no-bg"><i class="fas fa-crosshairs" aria-hidden="true"></i></div><span>Re-center</span></div>)'
        const centerButton = template.content.firstChild

        controlDiv.appendChild(centerButton)
        setCenterElement(centerButton)

        newMap.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(controlDiv)

        // Add generative controls
        if (generate) {
            controlDiv = document.createElement('div')
            template = document.createElement('template')
            template.innerHTML = '<div class="button topleft noselect with-icon alt"><div class="icon noselect no-bg"><i class="fas fa-sync-alt" aria-hidden="true"></i></div><span>Sync Places</span></div>)'
            const generateButton = template.content.firstChild

            controlDiv.appendChild(generateButton)
            setGenerateElement(generateButton)

            newMap.controls[google.maps.ControlPosition.LEFT_TOP].push(controlDiv)
        }

        // Bound map to given points
        /*if (markers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            markers.forEach((x) => {
                bounds.extend(x.position)
            })
            newMap.fitBounds(bounds)
        }*/
        setTimeout(() => {
            setMap(newMap)
        }, 250)
    }

    useEffect(() => {
        if (!markers) {
            return
        }
        const markerKey = markers.map((x) => x.id).join()
        if (!map || markers.length == 0 || (remember && (positionCache == null || (id != null && id in positionCache && positionCache[id].markerKey == markerKey)))) {
            return
        }

        fitToMarkers()
    }, [map, markers ? markers.map((x) => x.id).join() : null])

    useEffect(() => {
        if (!map || (markers && markers.length == 0)) {
            return
        }

        if (focusIds && focusIds.length > 0) {
            if (focusIds.length == 1) {
                const marker = markers?.find((x) => x.id == focusIds[0])
                if (marker && moveToFocus) {
                    panWithOffset(map, marker.position)
                }
            } else {
                const bounds = new window.google.maps.LatLngBounds();
                markers?.filter((x) => focusIds.find((y) => y == x.id)).forEach((x) => {
                    bounds.extend(x.position)
                })
                map.fitBounds(bounds, fitPadding)
            }
        }
        setFocusInitialized(true)
    }, [map, focusIds])

    function fitToMarkers() {
        // Bound map to given points
        if (markers.length == 1) {
            panWithOffset(map, markers[0].position, 12)
            map.setZoom(defaultZoom)
        } else {
            const bounds = new window.google.maps.LatLngBounds();
            markers.forEach((x) => {
                bounds.extend(x.position)
            })
            map.fitBounds(bounds, fitPadding)
        }
    }

    function handleReset() {
        if (onReset) {
            onReset()
        }
        setFocusElementGroup(null)
        setFocusElement(null)
        window.dispatchEvent(new CustomEvent(CustomEventType.CollapseMenus))
    }

    if (error) {
        return <div className="error-message"><h3>{error}</h3></div>
    }

    const mapStyle = {
        opacity: 0,
        visibility: 'hide',
        transition: 'opacity ease-in-out 100ms',
    }
    if (map != null) {
        mapStyle.opacity = 1
        mapStyle.visibility = 'inherit'
    }
    let index = 0
    return <React.Fragment>
        {sidebar && elements && <div id="map-sidebar" className="column col-4 scrollable">
            {title && <h3><ReactMarkdown>{title}</ReactMarkdown></h3>}
            {body && <span><ReactMarkdown>{body}</ReactMarkdown></span>}
            {onReset && <Button onClick={handleReset} tertiary>View All</Button>}
            {elements.map((x, ix) => {
                const startIndex = index
                return <CollapsibleRow id={ix} forceOpen={focusElement && focusElement > startIndex && focusElement <= startIndex + x.elements.length} title={x.title} style="plus" onOpen={(y) => handleElementGroup(y ? ix : null)}>
                    {x.elements.map((y, iy) => {
                        index += 1
                        const idx = index
                        let selectedClass = ''
                        if (focusElement != null) {
                            if (focusElement == index) {
                                selectedClass = ' focused'
                            } else {
                                selectedClass = ' unfocused'
                            }
                        }
                        return <React.Fragment>
                            {y.heading && <h5 className="map-sidebar-heading" style={{ marginTop: iy > 0 ? '20px' : '' }}>{y.heading}</h5>}
                            <span
                                id={`map-sidebar-entry-${y.id}`}
                                className={`map-sidebar-entry row${selectedClass}`}
                                // onMouseOver={screen.isMobile ? null : (e) => handleElementMouseOver(e, idx)}
                                // onMouseLeave={screen.isMobile ? null : (e) => handleElementMouseLeave(e, idx)}
                                onClick={(e) => handleElementFocus(e, idx)}>
                                <div className="map-circle">{index}</div><span>{y.title}</span>
                            </span>
                        </React.Fragment>
                    })}
                </CollapsibleRow>
            })}
        </div>}
        <div id="map-view" className="column">
            {!map && <Spinner overlay invert={invert} />}
            <div id={`googlemap-${id}`} className="googlemap" style={mapStyle} ref={setMapRef}></div>
            {map && markers && markers.map((x, ix) => {
                return <PopupMarker key={x.id} focus={x.id != null && focusIds.find((y) => y == x.id) != null} center={x.id == defaultMarker} map={map} defaultZoom={defaultZoom} hideMobile={hideMobile} {...x} />
            })}
        </div>
    </React.Fragment>
}