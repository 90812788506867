import React, { useEffect, useState } from 'react'
import { PromptType, UserOrderRecordData, UpgradeOrderStatus, PromptOptions, CustomEventType, MediaType } from 'app/types'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import { Input, Button, Spinner, IconButton, DropdownMenu, Toggle, Checkbox, DateSelector } from 'components'
import { createUpgradeDocument, notifyUpgrade, showPrompt } from 'actions/appActions'
import { validateEmail, validatePhone } from 'helpers/authHeader'
import * as fnc from 'helpers/fnc'
import { transformFloorplanName, transformFloorplanVariationName, transformUnitName } from 'app/transformers'
import { logger } from 'helpers/logger'
import { getMediaLink } from 'helpers/media'
import { upgradeOrderStatusIcons } from 'app/constants'
import { DatePicker } from 'rc-datepicker'
import 'rc-datepicker/lib/style.css'

export enum AddMode {
    Single = 'single',
    Multi = 'multi',
}

interface ClientRowProps {
    app: AppData,
    editing: boolean,
    organization: OrganizationData,
    user: UserData,
    order: UserOrderRecordData,
    onAdd: () => void,
    onDelete: () => void,
    onClick: () => void,
    onChange: () => void,
    onEditUserOrder: () => void,
    onEditUserOrderStart: () => void,
    onEditUpgradeOrder: () => void,
    onShowDocuments: () => void,
    maps: Dict,
    last: boolean,
    selected: { userOrder: UserOrderRecordData, upgradeView: UpgradeViewData }
    mobileView: boolean,
    record: Dict,
    allClients: Dict[],
}

export function ClientRow(props: ClientRowProps) {
    const { app, editing, organization, upgrades, user, order, onAdd, onDelete, onClick, onChange, onEditUserOrder, onEditUserOrderStart, onEditUpgradeOrder, onShowDocuments, maps, last, selected, mobileView, allClients } = props

    const dispatch = useAppDispatch()
    const media = useAppSelector((state: RootState) => state.app.media)
    const [editOrder, setEditOrder] = useState(onAdd ? { appId: app.meta.id } : null)
    const [refreshKey, setRefreshKey] = useState(Date.now())
    const [adding, setAdding] = useState(false)
    const [addMode, setAddMode] = useLocalStorage('user-add-mode', AddMode.Single)
    const [csvFormat, setCsvFormat] = useLocalStorage('user-add-csv-format', 'name, email, phone, floorplan, unit, price')
    const [csvData, setCsvData] = useState(null)
    const [onlyUpdate, setOnlyUpdate] = useState(false)

    useEffect(() => {
        if (editing) {
            setEditOrder({ ...order, appId: app.meta.id, name: order?.name || user?.name, email: order?.email || user?.email, phone: order?.phone || user?.phone })
        }
    }, [])

    function handleOrderField(field, value) {
        const newEditOrder = { ...editOrder, [field]: value }
        if (field == 'floorplanVariationId' && 'unitId' in newEditOrder) {
            delete newEditOrder.unitId
        }
        setEditOrder(newEditOrder)
    }

    function submitData(data) {
        if (onEditUserOrder) {
            setAdding(true)
            onEditUserOrder(data)
                .then((x) => {
                    if (!x.payload.success || (x.payload.failures && x.payload.failures.length > 0)) {
                        let message = ''
                        if (x.payload.failures && x.payload.failures.length > 0) {
                            message = `${x.payload.failures.map((x) => JSON.stringify(x))}`
                        } else {
                            message = x.payload.message
                        }
                        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Failed to edit user records', message }))
                    } else {
                        setEditOrder({ appId: app.meta.id })
                    }
                    setAdding(false)
                    setRefreshKey(Date.now())
                })
        } else if (onAdd) {
            setAdding(true)
            onAdd(data)
                .then((x) => {
                    if (!x.payload.success || (x.payload.failures && x.payload.failures.length > 0)) {
                        let message = ''
                        if (x.payload.failures && x.payload.failures.length > 0) {
                            message = `${x.payload.failures.map((x) => JSON.stringify(x))}`
                        } else {
                            message = x.payload.message
                        }
                        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Failed to add user records', message }))
                    } else {
                        setEditOrder({ appId: app.meta.id })
                    }
                    setAdding(false)
                    setRefreshKey(Date.now())
                })
        }
    }

    function handleSubmit() {
        if (adding) {
            return
        }
        function validateUser(x) {
            if (!x.floorplanVariationId && !x.error?.floorplan) {
                return { floorplan: true, message: ['Missing floorplan'] }
            }
            if (!x.name && !x.email) {
                return { name: true, message: ['Missing name'] }
            }

            // if (!x.unitId) {
            // return 'Missing unit'
            // }
            // if (!x.email) {
            // return 'Missing email'
            // }
            const invalidEmail = x.email && validateEmail(x.email)
            if (invalidEmail) {
                return { message: [invalidEmail], email: true }
            }
            const invalidPhone = x.phone && validatePhone(x.phone)
            if (invalidPhone) {
                return { message: [invalidPhone], phone: true }
            }
            return null
        }

        if (addMode == AddMode.Multi && editOrder.csv) {
            const clientMap = allClients ? allClients.reduce((acc, x) => {
                if (x.email && x.email.length > 0) {
                    return ({ ...acc, [x.email.toLowerCase()]: x })
                } else if (x.name && x.name.length > 0) {
                    return ({ ...acc, [x.name.toLowerCase()]: x })
                } else {
                    return acc
                }
            }, {}) : {}
            if (!csvData) {
                try {
                    const lines = editOrder.csv.split(/\r?\n/)
                    let commas = (editOrder.csv.match(/,/g) || []).length
                    let tabs = (editOrder.csv.match(/\t/g) || []).length
                    const separator = commas > tabs ? ',' : '\t'
                    const format = csvFormat.split(',').map((x) => x.trim().toCamelCase())
                    if (!onlyUpdate && !format.includes('email') && !format.includes('name')) {
                        throw new Error('Missing email or name')
                    }
                    const newData = []
                    lines.forEach((x) => {
                        const tokens = x.split(separator).map((x) => x.trim())
                        if (tokens.length < format.length) {
                            return
                        }

                        // If tokens match format, ignore
                        if (tokens.every((x, i) => x.trim().toCamelCase() == format[i])) {
                            return
                        }

                        let newUser = { appId: app.meta.id, error: { message: [] } }
                        for (let i = 0; i < format.length; i += 1) {
                            if (format[i].length > 0 && i < tokens.length) {
                                newUser[format[i]] = tokens[i].length > 0 ? tokens[i] : null
                            }
                        }

                        const parseDate = (x) => {
                            const ret = x.split('/')
                            if (ret.length == 3) {
                                ret.reverse()
                            }
                            return ret.join('-')
                        }

                        if (newUser.agreementDate) {
                            newUser.agreementDate = parseDate(newUser.agreementDate)
                        }
                        if (newUser.appointmentDate) {
                            newUser.appointmentDate = parseDate(newUser.appointmentDate)
                        }

                        if (newUser.phone) {
                            newUser.phone = newUser.phone.toAlphanum()
                        }

                        if (newUser.unit) {
                            const unitName = transformUnitName(app, { name: newUser.unit })
                            const unit = app.maps.unitName[unitName]
                            if (unit) {
                                newUser.unitId = unit.id
                            } else {
                                newUser.error.unit = true
                                newUser.error.message.push(`Failed to find unit ${newUser.unit}`)
                            }
                        }

                        // Convert floorplan to floorplanVariationId
                        if (newUser.floorplan) {
                            const floorplanLink = transformFloorplanName(app, { name: newUser.floorplan }).toLowerCase().replace(' ', '')
                            const floorplan = app.floorplans.find((x) => x.link.toLowerCase() == floorplanLink || x.name.toLowerCase() == floorplanLink)//.maps.floorplanLink[floorplanLink]
                            let floorplanVariation = null
                            if (floorplan && floorplan.variations.length > 0) {
                                if (floorplan.variations.length > 1) {
                                    const floorplanVariationLink = transformFloorplanVariationName(app, { name: newUser.floorplan })
                                    floorplanVariation = floorplan.variations.find((x) => x.link == floorplanVariationLink)
                                }
                                if (!floorplanVariation) {
                                    let floor = null
                                    if (newUser.unit) {
                                        // Get floor from first character
                                        floor = parseInt(newUser.unit[0])
                                    }
                                    // Extract floor from variation

                                    if (floor != null) {
                                        floorplan.variations.forEach((x) => {
                                            if (floorplanVariation) {
                                                return
                                            }
                                            const regex = /(\d+)(?:\s*-\s*(\d+))?/
                                            const match = x.name.match(regex)

                                            if (match) {
                                                const min = parseInt(match[1], 10)
                                                const max = match[2] ? parseInt(match[2], 10) : min
                                                if (floor >= min && floor <= max) {
                                                    floorplanVariation = x
                                                }
                                            }
                                        })
                                    }

                                }
                                if (!floorplanVariation) {
                                    floorplanVariation = floorplan.variations[0]
                                }
                                newUser.floorplanVariationId = floorplanVariation.id
                            }
                            if (!newUser.floorplanVariationId) {
                                newUser.error.floorplan = true
                                newUser.error.message.push(`Failed to find floorplan ${newUser.floorplan}`)
                            }

                        }

                        // Find existing user
                        if (onlyUpdate) {
                            if (newUser.unitId) {
                                let matchingOrders = []
                                allClients.forEach((x) => {
                                    x.orders.forEach((y) => {
                                        if (y.unitId == newUser.unitId) {
                                            newUser.existing = {}
                                            const fields = ['price', 'bookingLink', 'agreementDate', 'appointmentDate', 'designCredit']
                                            fields.forEach((x) => {
                                                if (y[x] != newUser[x]) {
                                                    newUser.existing[x] = newUser[x]
                                                }
                                            })
                                            matchingOrders.push({ ...x, ...y, ...newUser })
                                        }
                                    })
                                })

                                matchingOrders.forEach((x) => {

                                    // Convert unit to unitId
                                    const validate = validateUser(x)
                                    if (validate) {
                                        const { message, ...rest } = validate
                                        x.error = { ...x.error, message: [...x.error.message, ...message], ...rest }
                                        // throw new Error(`${validate} - ${JSON.stringify(newUser)}`)
                                    }
                                    newData.push(x)
                                })
                            }
                        } else {
                            const existing = allClients.find((x) => {
                                let matchingUnit = true
                                let matchingFloorplan = true
                                if (newUser.unitId) {
                                    matchingUnit = x.orders.find((y) => y.unitId == newUser.unitId)
                                }
                                if (newUser.floorplanVariationID) {
                                    matchingFloorplan = !x.orders.find((y) => y.floorplanVariationId != newUser.floorplanVariationId)
                                }

                                const matchingEmail = newUser.email != null && newUser.email.toLowerCase() == x.email?.toLowerCase()
                                const matchingName = newUser.name != null && newUser.name.toLowerCase() == x.name?.toLowerCase()

                                if (newUser.name == null || (newUser.email?.length > 0)) {
                                    return matchingEmail && matchingUnit && matchingFloorplan
                                } else if (newUser.name && newUser.email) {
                                    return matchingName && matchingEmail && matchingUnit && matchingFloorplan
                                } else {
                                    return matchingName && matchingUnit && matchingFloorplan
                                }
                            })
                            if (existing) {
                                const order = existing.orders.find((x) => {
                                    if (newUser.unitId && x.unitId != newUser.unitId) {
                                        return false
                                    }
                                    if (newUser.floorplanVariationId && x.floorplanVariationId != newUser.floorplanVariationId) {
                                        return false
                                    }
                                    return x.appId == app.meta.id
                                })
                                if (order) {
                                    newUser.existing = {}
                                    if (existing.email != newUser.email) {
                                        newUser.existing.email = newUser.email
                                    }
                                    if (existing.name != newUser.name) {
                                        newUser.existing.name = newUser.name
                                    }
                                    if (existing.phone != newUser.phone) {
                                        newUser.existing.phone = newUser.phone
                                    }
                                    const fields = ['unitId', 'floorplanVariationId', 'price', 'bookingLink', 'agreementDate', 'appointmentDate', 'designCredit']
                                    fields.forEach((x) => {
                                        if (order[x] != newUser[x]) {
                                            newUser.existing[x] = newUser[x]
                                        }
                                        if (!format.includes(x)) {
                                            newUser[x] = order[x]
                                        }
                                    })
                                }
                            }
                            /*if (newUser.email || newUser.name) {
                                const existing = newUser.email.toLowerCase() in clientMap ? clientMap[newUser.email.toLowerCase()] : (newUser.name.toLowerCase() in clientMap ? clientMap[newUser.name.toLowerCase()] : null)
                                newUser.existing = null
                                if (existing) {
                                    newUser.existing = {}
                                    if (existing.email != newUser.email) {
                                        newUser.existing.email = newUser.email
                                    }
                                    if (existing.name != newUser.name) {
                                        newUser.existing.name = newUser.name
                                    }
                                    if (existing.phone != newUser.phone) {
                                        newUser.existing.phone = newUser.phone
                                    }
                                    if (existing.unitId != newUser.unitId) {
                                        newUser.existing.unitId = newUser.unitId
                                    }
                                    if (existing.floorplanVariationId != newUser.floorplanVariationId) {
                                        newUser.existing.floorplanVariationId = newUser.floorplanVariationId
                                    }
                                    if (existing.price != newUser.price) {
                                        newUser.existing.price = newUser.price
                                    }
                                }
                            }*/


                            // Convert unit to unitId
                            const validate = validateUser(newUser)
                            if (validate) {
                                const { message, ...rest } = validate
                                newUser.error = { ...newUser.error, message: [...newUser.error.message, ...message], ...rest }
                                // throw new Error(`${validate} - ${JSON.stringify(newUser)}`)
                            }
                            newData.push(newUser)
                        }
                    })
                    setCsvData(newData)
                } catch (e) {
                    dispatch(showPrompt({ type: PromptType.Confirm, title: 'Error parsing separated fields', message: e.toString() }))
                }
            } else {
                submitData(csvData)
            }
        } else {
            const validate = validateUser(editOrder)
            if (validate) {
                logger.info('Invalid user', editOrder, validate)
                dispatch(showPrompt({ type: PromptType.Confirm, title: 'Error creating user', message: validate.message.join(', ') }))
                return
            }
            submitData([editOrder])
        }
    }

    function handleDelete(x) {
        if (onDelete) {
            onDelete(user, x)
        }
    }

    function handleEdit(x) {
        if (onEditUserOrderStart) {
            onEditUserOrderStart({ user, order: x })
        }
    }

    function handleClick(order, view) {
        onClick(order, view, true)
    }


    function handleDocumentDelete(media, record = null, signed = false) {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Delete Document', message: 'Are you sure you want to delete this document?' }))
            .then((x) => {
                if (x.payload) {
                    const newRecord = { ...record }
                    newRecord.documents = newRecord.documents.filter((x) => !(x.mediaId == media.id && x.signed == signed))
                    if (onEditUpgradeOrder) {
                        onEditUpgradeOrder(newRecord)
                    }
                }
            })
    }

    function handleNotifyView(view) {
        // Find upgrade order for this 
        const record = user.orders.find((x) => x.appId == app.meta.id)
        if (record) {
            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Email User?', message: `Are you sure you want to invite ${user.name} to the upgrades program?` }))
                .then((x) => {
                    if (!x.payload) {
                        return
                    }
                    const order = record.upgradeOrderRecords.find((x) => x.upgradeViewId == view.id)

                    const notifyData = {
                        userId: user.id,
                        userOrderRecordId: record.id,
                        upgradeViewId: view.id,
                    }
                    let prom = [
                        dispatch(showPrompt(PromptOptions.NotifySending)),
                        dispatch(notifyUpgrade({ app, organization, data: { orders: [notifyData] } }))
                    ]
                    Promise.all(prom).then((x) => {
                        if (x[1].payload.success) {
                            if (onChange) {
                                onChange(user, x[1].payload.sends[0].order)
                            }
                        }
                    })
                })
        }
    }

    function handleAction(x, y) {
        if (y == 'edit') {
            handleEdit(x)
        } else if (y == 'delete') {
            handleDelete(x)
        } else {
            // Fix action
            const idx = order.upgradeOrderRecords.findIndex((z) => z.id == x.id)
            if (idx != -1) {
                const newUpgradeOrderRecord = { ...order.upgradeOrderRecords[idx], upgradeOrderStatusId: y }
                if (onEditUpgradeOrder) {
                    onEditUpgradeOrder(newUpgradeOrderRecord)
                }

                if (editing) {
                    // Update upgrade order
                    const idx = editOrder.upgradeOrderRecords.findIndex((z) => z.id == x.id)
                    if (idx != -1) {
                        const newOrder = { ...editOrder }
                        const newRecords = [...newOrder.upgradeOrderRecords]
                        newRecords[idx] = { ...newRecords[idx], upgradeOrderStatusId: y }
                        newOrder.upgradeOrderRecords = newRecords
                        setEditOrder(newOrder)
                    }
                }
            }
            // Is a status change
            // submitData({ ...x, upgradeOrderStatusId: y })

        }
    }


    if (editing && (onAdd || onEditUserOrder)) {
        if (!editOrder) {
            return null
        }
        let planItems = []
        let unitItems = null
        const plans = [...app.floorplans].sort((a, b) => {
            const numA = a.name.match(/\d+/)
            const numB = b.name.match(/\d+/)
            if (numA && numB) {
                return parseInt(numA[0]) - parseInt(numB[0])
            } else {
                return a.name.localeCompare(b.name)
            }
        })
        plans.forEach((x) => {
            x.variations.forEach((y) => {
                planItems.push({ text: x.variations.length > 1 ? `${x.name} - ${y.name}` : x.name, value: y.id })
            })
        })

        let floorplanVariation = null
        let floorplan = null
        if (editOrder.floorplanVariationId) {
            floorplanVariation = app.maps.floorplanVariation[editOrder.floorplanVariationId]
            if (floorplanVariation) {
                floorplan = app.maps.floorplan[floorplanVariation.floorplanId]
                // unitItems = app.units.filter((x) => x.floorplans.includes(floorplan.id)).map((x) => ({ text: x.name, value: x.id }))
                unitItems = app.units.filter((x) => x.floorplans.find((x) => x.floorplanId == floorplan.id)).map((x) => ({ text: x.name, value: x.id }))
            }
        }
        const editing = onEditUserOrder != null
        const lockUser = false
        const errors = csvData ? csvData.filter((x) => x.error.message.length > 0).length : 0

        const parseDate = (dateString) => {
            if (!dateString) {
                return ''
            }
            let ret = new Date(`${dateString.replaceAll('/', '-')}T12:00:00Z`)
            // If date is invalid, reset
            if (ret.toString() == 'Invalid Date') {
                ret = new Date()
            }
            return ret
        }

        let agreementDate = new Date()
        let appointmentDate = new Date()
        if (editing) {
            if (editOrder.agreementDate) {
                agreementDate = parseDate(editOrder.agreementDate)
            }
            if (editOrder.appointmentDate) {
                appointmentDate = parseDate(editOrder.appointmentDate)
            }
        }

        const format = csvFormat.split(',').map((x) => x.trim().toCamelCase())
        if (onlyUpdate) {
            format.unshift('email')
            format.unshift('name')
        }

        return <div className={`client-adder${addMode == AddMode.Multi ? ' multi' : ''}`} key={refreshKey} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
            <div className="column" style={{ opacity: adding ? 0 : 1 }}>
                <div className="row">
                    <h4>{onEditUserOrder ? 'Edit User' : 'Add User'}</h4>
                </div>
                {!editing && <div className="row">
                    <Toggle items={Object.keys(AddMode).map((x) => ({ text: x, value: AddMode[x] }))} onChange={setAddMode} value={addMode} />
                    {addMode == AddMode.Multi && <div className="top-right"><Checkbox value={onlyUpdate} onChange={setOnlyUpdate}>Only Update</Checkbox></div>}
                </div>}
                <div className="row ">
                    {(editing || addMode == AddMode.Single) && <div className="column single">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    {lockUser && <td><span>{editOrder.name}</span></td>}
                                    {!lockUser && <td><Input value={editOrder.name} placeholder="Enter Name" onChange={(x) => handleOrderField('name', x)} /></td>}
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    {lockUser && <td><span>{editOrder.email}</span></td>}
                                    {!lockUser && <td><Input value={editOrder.email} placeholder="Enter Email" onChange={(x) => handleOrderField('email', x)} /></td>}
                                </tr>
                                <tr>
                                    <th>Phone</th>
                                    {lockUser && <td><span>{editOrder.phone}</span></td>}
                                    {!lockUser && <td><Input phone value={editOrder.phone} placeholder="Enter Phone" onChange={(x) => handleOrderField('phone', x)} /></td>}
                                </tr>
                                <tr>
                                    <th>Booking Link</th>
                                    {lockUser && <td><span>{editOrder.bookingLink}</span></td>}
                                    {!lockUser && <td><Input value={editOrder.bookingLink} placeholder="Enter Booking Link" onChange={(x) => handleOrderField('bookingLink', x)} /></td>}
                                </tr>
                                <tr>
                                    <th>Appointment Date</th>
                                    {/* <td><DatePicker value={appointmentDate} onChange={(x) => handleOrderField('appointmentDate', fnc.formatDateToYYYYMMDD(x))} /></td> */}
                                    <td><DateSelector collapse selected={appointmentDate} onChange={(x) => handleOrderField('appointmentDate', fnc.formatDateToYYYYMMDD(x))} /></td>
                                </tr>
                                <tr>
                                    <th>Agreement Date</th>
                                    {/* <td><DatePicker value={agreementDate} onChange={(x) => handleOrderField('agreementDate', fnc.formatDateToYYYYMMDD(x))} /></td> */}
                                    <td><DateSelector collapse selected={agreementDate} onChange={(x) => handleOrderField('agreementDate', fnc.formatDateToYYYYMMDD(x))} /></td>
                                </tr>
                                <tr>
                                    <th>Design Credit</th>
                                    <td><Input price value={editOrder.designCredit} placeholder="Enter Design Credit" onChange={(x) => handleOrderField('designCredit', x)} /></td>
                                </tr>
                                <tr>
                                    <th>Floorplan</th>
                                    <td><DropdownMenu buttonClass="button-tertiary" items={planItems} text={!floorplan ? 'Select Floor Plan' : `Floorplan: ${floorplan.name}`} value={editOrder?.floorplanVariationId} onChange={(x) => handleOrderField('floorplanVariationId', x)} /></td>
                                </tr>
                                {unitItems && <tr>
                                    <th>Units</th>
                                    <td><DropdownMenu buttonClass="button-tertiary" items={unitItems} text={(!editOrder?.unitId || !(editOrder.unitId in app.maps.unit)) ? 'Select Unit' : `Unit: ${app.maps.unit[editOrder.unitId].name}`} value={editOrder?.unitId} onChange={(x) => handleOrderField('unitId', x)} /></td>
                                </tr>}
                                {editOrder.unitId && <tr>
                                    <th>Unit Price</th>
                                    <td><Input price value={editOrder.price} placeholder="Enter Unit Price" onChange={(x) => handleOrderField('price', x)} /></td>
                                </tr>}
                                <tr>
                                    <td colSpan={2}>
                                    </td>
                                </tr>
                                {editing && editOrder.upgradeOrderRecords.map((x) => {
                                    const view = maps.views[x?.upgradeViewId]
                                    const unit = app.maps.unit[editOrder.unitId]

                                    const items = []
                                    Object.keys(UpgradeOrderStatus).filter((x) => isNaN(x)).forEach((x) => {
                                        items.push({ text: x.toReadable(), value: UpgradeOrderStatus[x], icon: upgradeOrderStatusIcons[UpgradeOrderStatus[x]] })
                                    })

                                    return <tr>
                                        <th>Unit {unit?.name} Status</th>
                                        <td>
                                            <DropdownMenu buttonClass={`alt view-option`} value={x.upgradeOrderStatusId} icon={upgradeOrderStatusIcons[x.upgradeOrderStatusId]} text={UpgradeOrderStatus[x.upgradeOrderStatusId]} items={items} onChange={(a) => handleAction(x, a)} />
                                        </td>
                                    </tr>

                                })}
                            </tbody>
                        </table>
                    </div>}
                    {!editing && addMode == AddMode.Multi && <div className="column multi">
                        <Input label="Format" value={csvFormat} onChange={setCsvFormat} />
                        {!csvData && <Input textarea placeholder={csvFormat} value={editOrder.csv} onChange={(x) => handleOrderField('csv', x)} />}
                        {csvData && <div className="table-wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        {format.map((x) => {
                                            return <th key={x}>{x.toReadable()}</th>
                                        })}
                                        <th>Error</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {csvData.map((x, ix) => {
                                        let floorplanVariation = null
                                        let floorplan = null
                                        let fpName = 'Missing floorplan ' + x.floorplan
                                        if (x.floorplanVariationId) {
                                            floorplanVariation = app.maps.floorplanVariation[x.floorplanVariationId]
                                            floorplan = app.maps.floorplan[floorplanVariation.floorplanId]
                                            fpName = floorplan.name
                                            if (floorplan.variations.length > 1) {
                                                fpName = `${fpName} - ${floorplanVariation.name}`
                                            }
                                        }
                                        const getColumnClass = (key) => {
                                            if (x.existing && key in x.existing) {
                                                return 'modified'
                                            }
                                            if (x.error && key in x.error) {
                                                return 'error'
                                            }
                                            return ''
                                        }

                                        return <tr key={`${x.name}-${ix}`} className={`${x.error.message.length > 0 ? 'with-error' : ''}${x.existing ? ' existing' : ''}`}>
                                            {format.map((key) => {
                                                let value = null
                                                switch (key) {
                                                    case 'phone':
                                                        value = fnc.phoneNumber(x[key])
                                                        break
                                                    case 'price':
                                                    case 'designCredit':
                                                        if (x[key] != null && x[key] != undefined && x[key] != '') {
                                                            value = fnc.toMoney(parseInt(x[key]))
                                                        } else {
                                                            value = ''
                                                        }
                                                        break
                                                    case 'unit':
                                                        value = x.unitId ? app.maps.unit[x.unitId].name : 'Missing unit ' + x.unit
                                                        break
                                                    case 'floorplan':
                                                        value = fpName
                                                        break
                                                    case 'agreementDate':
                                                        value = x.agreementDate.replaceAll('-', '/')
                                                        break
                                                    case 'appointmentDate':
                                                        value = x.appointmentDate.replaceAll('-', '/')
                                                        break
                                                    default:
                                                        value = x[key]
                                                        break

                                                }
                                                return <td className={getColumnClass(key)}>{value}</td>

                                            })}
                                            <td>{x.error?.message?.reduce((acc, currentValue, currentIndex, array) => acc.concat(currentValue, currentIndex < array.length - 1 ? <br /> : []), [])}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>}
                </div>
                <div className="row">
                    {editing && <Button tertiary onClick={handleSubmit}>Edit User</Button>}
                    {!editing && addMode == AddMode.Single && <Button tertiary onClick={handleSubmit}>Add User</Button>}
                    {!editing && addMode == AddMode.Multi && <React.Fragment>
                        <Button className={errors > 0 ? 'disabled' : ''} tertiary onClick={errors > 0 ? null : handleSubmit}>{csvData ? 'Add Users' : 'Add User'}</Button>
                        {csvData && <Button tertiary onClick={() => {
                            setCsvData(null)
                        }}>Reset</Button>}
                        {errors > 0 && csvData && <h4 className="error" style={{ margin: 'auto 0', width: 'auto' }}>Fix {errors} errors</h4>}
                    </React.Fragment>}
                </div>
            </div>
            {props.children}
            {adding && <Spinner showAfter={0} />}
        </div>
    }

    if (!user) {
        return <h3>Missing user</h3>
    }

    return <React.Fragment>
        {user.orders.filter((x) => {
            if (order) {
                return x.id == order.id && x.appId == app.meta.id
            }
            return x.appId == app.meta.id
        }).map((_y, iy) => {
            let y = _y
            if (selected && selected.userOrder.id == _y.id) {
                y = selected.userOrder
            }
            let floorplanVariation = null
            let floorplan = null
            let floorplanName = null
            let unitName = null
            try {
                floorplanVariation = app.maps.floorplanVariation[y.floorplanVariationId]
                floorplan = app.maps.floorplan[floorplanVariation.floorplanId]
                floorplanName = floorplan.name
                if (floorplan.variations.length > 1) {
                    floorplanName = `${floorplanName} - ${floorplanVariation.name} `
                }

                if (y?.unitId && y.unitId in app.maps.unit) {
                    unitName = y?.unitId ? app.maps.unit[y?.unitId].name : ''
                }
            } catch (e) {
                logger.error(e)
                return <tr><td colSpan={6}>Error loading user order {y.email}</td></tr>
            }

            const viewSet = {}
            y.upgradeOrderRecords.forEach((x) => {
                viewSet[x.upgradeViewId] = x
            })

            const options = <React.Fragment>
                {upgrades.views.map((z, iz) => {
                    const isSelected = selected && selected.userOrder && selected.upgradeView && selected.userOrder.id == y.id && selected.upgradeView.id == z.id
                    const record = viewSet[z.id]
                    const status = record ? record.upgradeOrderStatusId : UpgradeOrderStatus.Ready

                    const items = []
                    /*items.push({ value: z, test: z.name, icon: 'fas fa-paper-plan' })
                    if (record?.documents.length > 0) {
                        record.documents.forEach((x, ix) => {
                            const docMedia = media[x.mediaId]
                            if (docMedia) {
                                const extension = docMedia.link.split('.').pop().toUpperCase()
                                items.push({
                                    header: ix == 0 ? 'Documents' : null, value: docMedia, text: `${extension} - ${fnc.dateFriendly(new Date(docMedia.dateCreated), { time: true, days: true, dayOfTheWeek: true })}`,
                                })
                            }
                            return { value: null, text: 'Missing Document' }
                        })
                    }*/
                    Object.keys(UpgradeOrderStatus).filter((x) => isNaN(x)).forEach((x) => {
                        items.push({ text: x.toReadable(), value: UpgradeOrderStatus[x], icon: upgradeOrderStatusIcons[UpgradeOrderStatus[x]] })
                    })

                    const signedDocuments = record?.documents.filter((x) => x.signed)
                    const unsignedDocuments = record?.documents.filter((x) => !x.signed)

                    return <React.Fragment>
                        <Button alt key={`${z.id} -${iz} `} icon={upgradeOrderStatusIcons[status]} className={`view-option ${isSelected ? 'selected' : ''} `} onClick={() => {
                            handleClick(y, z)
                        }}>{z.name}</Button>
                        {/* <DropdownMenu buttonClass={`alt view-option`} value={status} icon={upgradeOrderStatusIcons[status]} items={items} onChange={(a) => handleAction(record, a)} /> */}
                        <Button alt counter={record?.documents.length} onClick={() => onShowDocuments(record)} icon="fas fa-file-pdf">Documents</Button>
                        {/*<DropdownMenu counter={unsignedDocuments.length > 0} className={!unsignedDocuments.length ? 'disabled' : ''} items={unsignedDocuments.map((x) => {
                            const docMedia = media[x.mediaId]
                            if (docMedia) {
                                const extension = docMedia.link.split('.').pop().toUpperCase()
                                return {
                                    value: docMedia, text: `${extension} - ${fnc.dateFriendly(new Date(docMedia.dateCreated), { time: true, days: true, dayOfTheWeek: true })}`,
                                }
                            }
                            return { value: null, text: 'Missing Document' }
                        })} buttonClass="alt" icon="fas fa-file-pdf" onChange={handleDocument} />
                        <DropdownMenu counter={signedDocuments.length > 0} items={[{ value: 'add', text: 'Add Signed Document', count: false }, ...signedDocuments.map((x) => {
                            const docMedia = media[x.mediaId]
                            if (docMedia) {
                                const extension = docMedia.link.split('.').pop().toUpperCase()
                                let dateString = ''
                                if (x.signedDate != null) {
                                    dateString = `Signed ${fnc.dateFriendly(new Date(x.signedDate), { time: false, days: true, dayOfTheWeek: true })}`
                                } else {
                                    dateString = `Uploaded ${fnc.dateFriendly(new Date(docMedia.dateCreated), { time: true, days: true, dayOfTheWeek: true })}`
                                }
                                return {
                                    value: docMedia, text: `${extension} - ${dateString}`
                                }
                            }
                            return { value: null, text: 'Missing Document' }
                        })]} buttonClass="alt" icon="fas fa-file-signature" onChange={(x) => handleDocument(x, record, true)} onDelete={(x) => handleDocumentDelete(x, record, true)} />*/}
                    </React.Fragment>
                })}
                {upgrades.views.length > 1 && <DropdownMenu items={upgrades.views.map((x) => ({ value: x, text: x.name }))} onChange={(x) => handleNotifyView(x)} buttonClass="alt" icon="fas fa-paper-plane" text="Notify" />}
                {upgrades.views.length == 1 && <Button onClick={() => handleNotifyView(upgrades.views[0])} alt icon="fas fa-paper-plane">Email</Button>}
                <DropdownMenu items={[{ icon: 'fas fa-pen', text: 'Edit', value: 'edit' }, { icon: 'fas fa-trash', text: 'Delete', value: 'delete' }]} onChange={(x) => handleAction(y, x)} buttonClass="alt" icon="fas fa-ellipsis-v" />
                {/* <IconButton alt icon="fas fa-pen" onClick={() => handleEdit(y)} /> */}
                {/* <IconButton alt icon="fas fa-trash" onClick={() => handleDelete(y)} /> */}
            </React.Fragment>

            if (mobileView) {
                return <React.Fragment key={`${y.id} -${iy} `}>
                    <tr className="client-order-record only-title">
                        <td>
                            <span>{user.name}</span>
                        </td>
                        <td>
                            <span>{user.email}</span>
                        </td>
                        <td>
                            <h5>{fnc.phoneNumber(user.phone)}</h5>
                        </td>
                        <td>
                            <span>{floorplanName}</span>
                        </td>
                        <td>
                            <span>{unitName}</span>
                        </td>
                        <td>
                            <span>{y.price ? fnc.toMoney(y.price) : 'Not Set'}</span>
                        </td>
                        {/*<td>
                            <span>{y.bookingLink}</span>
                        </td>*/}
                    </tr>
                    <tr className="client-order-record">
                        <td colSpan={5}>
                            <div className="row">
                                {options}
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            } else {
                return <tr className={`client-order-record`} key={`${y.id} -${iy} `}>
                    <td>
                        <span>{user.name}</span>
                    </td>
                    <td>
                        <span>{user.email}</span>
                    </td>
                    <td>
                        <span>{fnc.phoneNumber(user.phone)}</span>
                    </td>
                    <td>
                        <span>{floorplanName}</span>
                    </td>
                    <td>
                        <span>{unitName}</span>
                    </td>
                    <td>
                        <span>{y.price ? fnc.toMoney(y.price) : 'Not Set'}</span>
                    </td>
                    {/*<td>
                        <span>{y.bookingLink}</span>
            </td>*/}
                    <td>
                        <div className="column" style={{ flexDirection: 'row' }}>
                            {options}
                        </div>
                    </td>
                </tr>
            }
        })}
    </React.Fragment>
}