import React, { useState, useEffect } from 'react'
import { AnalyticsEvent, AppData, CustomContentType, CustomEventType, MediaType, PageType, PromptOptions, ThumbSize } from 'app/types'
import { Media, SlideShow, WaterMark, Button, Message } from 'components'
import { IntersectionHelper } from 'views/DynamicPage/IntersectionHelper'
import { Link } from 'react-router-dom'
import { showPrompt } from 'actions/appActions'
import { useAdminPermissions, useAppDispatch, useDynamicContent, useHybridContent, usePage } from 'app/hooks'
import { DynamicContent } from './DynamicContent'
import { Provinces, referralSources } from 'app/constants'
import { organizationContent, organizationStaticContent, projectStaticContent } from 'app/transformers'

interface DynamicContentViewProps {
    app: AppData,
    oraganization: OrganizationData,
    link: string
    show: boolean,
    mobile: boolean,
    onSelect,
}

const waterfallContent = new Set(['footer'])

export function DynamicContentView(props: GatesOfMeafordContentProps) {
    const dispatch = useAppDispatch()
    const { app, builder, organization, link, show, mobile, onSelect, ...otherProps } = props
    const hybridContent = useHybridContent()
    const adminPermissions = useAdminPermissions()
    const page = usePage()
    let customContent
    if (app) {
        customContent = projectStaticContent(app)
    }


    if (organization && (!customContent || (waterfallContent.has(link) && !(link in customContent))) && !(app?.dynamicContent?.find((x) => x.link == link))) {
        customContent = organizationStaticContent(organization)
    }

    const finalComponents = hybridContent(app, builder, organization, { ...(props.page || page), link }, customContent)
    let isAdmin = false
    if (adminPermissions && app) {
        isAdmin = adminPermissions(app)
    }
    const filterDraft = (x) => {
        if (x.draft && !isAdmin) {
            return false
        }
        return true
    }

    if (finalComponents.length == 0) {
        if (link != 'footer') {
            return <Message type="error" info="No content found" />
        }
    }
    const headerComponent = finalComponents.find((x) => x.dynamicContentTypeId == CustomContentType.Header && filterDraft(x))
    const bodyComponents = finalComponents.filter((x) => x.dynamicContentTypeId != CustomContentType.Header && filterDraft(x))
    const bodyElem = bodyComponents.map((x, ix) => <DynamicContent key={ix} idx={x.order} app={app} organization={organization} {...x} {...otherProps} />)

    if (link == "footer") {
        return bodyElem
    }

    return <React.Fragment>
        <IntersectionHelper />
        {headerComponent && <DynamicContent key={0} idx={headerComponent.order} app={app} organization={organization} {...headerComponent} {...otherProps} />}
        <div id="custom-view" className="content-view" key={link} data-link={link}>
            {bodyElem}
        </div>
    </React.Fragment>

}